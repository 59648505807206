export default {
  data() {
    return {
      initializedMap: false,
      map: null,
      showMap: true,
      apiKey: "AIzaSyBkIThI9UvURwtAIIPyaXFRVNxB_QubBIY",
    };
  },
  mounted() {
    this.initMap();
  },
  methods: {
    initMap() {
      // Só carrega o script da API do Google Maps se ele ainda não tiver sido carregado
      if (!window.google || !window.google.maps) {
        window.initializeMap = this.initializeMap; // Adicione esta linha
        const script = document.createElement("script");
        script.src = `https://maps.googleapis.com/maps/api/js?key=${this.apiKey}&libraries=places&callback=initializeMap`;
        script.defer = true;
        script.async = true;
        document.head.appendChild(script);
      } else {
        this.initializeMap();
      }
    },
    initializeMap() {
      // Este método será chamado automaticamente quando o script da API do Google Maps tiver carregado
      this.initializedMap = true;
      // eslint-disable-next-line no-undef
      this.map = new google.maps.Map(document.getElementById("map"), {
        center: { lat: -30.034647, lng: -51.217658 },
        zoom: 10,
      });
      this.addMarkers();
      this.showMap = false;
      delete window.initializeMap; // Remove initializeMap do objeto window depois que é chamado
    }
  },
};