import axios from 'axios'
import router from '../../router/index'
export default {
    state: {
        user: {},
        token: null,
        logged: false,
        debugAxiosRequest: false,
        isAuthenticated: false,
    },
    mutations: {
        setAuth(state, valor) {
            state.isAuthenticated = valor;
        },
        setLogged(state, payload) {
            state.logged = payload
        },
        setUser(state, payload) {
            state.user = payload
            state.logged = true
        },
        setToken(state, payload) {
            state.logged = true
            state.token = payload
            axios.defaults.headers.common['Authorization'] = `Bearer ${payload}`
            localStorage.setItem('pucVUE', payload)
            let data = payload.split('.')
            state.user = JSON.parse(atob(data[1]))
        },
        destroyUser(state, payload) {
            state.logged = false
            if (payload) {
                state.token = null
            }
            state.user = {}
            delete axios.defaults.headers.common['Authorization']
            localStorage.removeItem('pucVUE')
            router.push('/login')
        },
        setdebugAxiosRequest(state, payload) {
            state.debugAxiosRequest = payload
        },
        login(context, payload) {
            axios.post(`/token/`, payload).then((resp) => {
                // console.log(resp.data);
                if (resp.status < 300) {
                    context.dispatch("setToken", resp.data.access);
                    context.dispatch("resetSnack");
                    context.dispatch("showSuccessSnack", "Logado com Sucesso");
                }
            });
        },
    },
    actions: {
        logOff(context) {
            this.state.logged = false
            context.commit('destroyUser', [])
            delete axios.defaults.headers.common['Authorization']
            localStorage.removeItem('pucVUE')
        },
        setLogged(context, payload) {
            context.commit('setLogged', payload)
        },
        setToken(context, payload) {
            context.commit('setToken', payload)
        },
        login(context, payload) {
            context.commit('login', payload)
        },
        setdebugAxiosRequest(context, payload) {
            context.commit('setdebugAxiosRequest', payload)
        },
    },
    getters: {
        getUsuario(state) {
            return state.user
        },
        getToken(state) {
            return state.token
        },
        getLogged(state) {
            return state.logged
        },
        getDefaultRoute(state) {
            return state.user.route
        },
        getDebugAxiosRequest(state) {
            return state.debugAxiosRequest
        },
    }
}