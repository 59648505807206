import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import './plugins/axios'
import jsPDF from 'jspdf'
// import './plugins/efeitos';

Vue.config.productionTip = false;

// Verificar se há um token de usuário no armazenamento local (localStorage)
const token = localStorage.getItem('user-token');
if (token) {
  // Usuário está logado, então defina o estado de autenticação como verdadeiro
  store.commit('setAuth', true);
} else {
  // Usuário não está logado, então defina o estado de autenticação como falso
  store.commit('setAuth', false);
}

// Criação da instância Vue principal
new Vue({
  router,
  store,
  vuetify,
  jsPDF,
  render: (h) => h(App),
}).$mount("#app");
