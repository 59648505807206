import axios from 'axios'
import store from '../index'
export default {
    state: {
        segmentacao: [],
        segmentoAlvo: []
    },
    mutations: {
        setSegmentacao(state, payload) {
            state.segmentacao = payload
        },
        setSegmentoAlvo(state, payload) {
            state.segmentoAlvo = payload
        },
    },
    actions: {
        setSegmentacao(context, payload) {
            context.commit('setSegmentacao', payload)
        },
        setSegmentoAlvo(context, payload) {
            context.commit('setSegmentoAlvo', payload)
        },
        loadSegmentacao(context, payload) {
            if (store.state.index.user.user.user_id === undefined) {
                return;
            }
            try {
                axios(`/segmentacao/${payload ? '?uf=' + payload : ''}`).then((resp) => {
                    if (resp.status < 300) {
                        context.commit('setSegmentacao', resp.data)
                    }
                });
            } catch (error) {
                console.log(error);
            }
        },
        loadSegmentoAlvo(context) {
            axios(`/segmentoalvo/`).then((resp) => {
                if (resp.status < 300) {
                    context.commit('setSegmentoAlvo', resp.data)
                }
            });
        }
    },
    getters: {
        getSegmentacao(state) {
            return state.segmentacao
        },
        getSegmentoAlvo(state) {
            return state.segmentoAlvo
        },
        getSegmentacaoSelect: (state) => {
            let gp = []
            state.segmentacao.forEach(element => {
                gp.push({ value: element.id, text: element.nome })
            });
            return gp
        },
        getSegmentoAlvoSelect: (state) => {
            let segmentoAlvoArray = Array.isArray(state.segmentoAlvo) ? state.segmentoAlvo : [state.segmentoAlvo];
            let gp = [];
            segmentoAlvoArray.forEach(element => {
                gp.push({ value: element.id, text: `${element.nome}` });
            });
            return gp;
        },
        getSegmentacaoById: (state) => (id) => {
            if (!state.segmentacao) {
                return id
            }
            let obj = state.segmentacao.find(g =>
                g.id == id
            )
            return obj
        },
        getSegmentacaoNameById: (state) => (id) => {
            if (!state.segmentacao) {
                return id
            }
            let obj = state.segmentacao.find(g =>
                g.id == id
            )
            if (obj) {
                return obj.nome
            } else {
                return ""
            }
        },
        getSegmentoAlvoById: (state) => (id) => {
            if (!state.segmentoAlvo) {
                return id
            }
            let obj = state.segmentoAlvo.find(g =>
                g.id == id
            )
            return obj
        },
        getSegmentoAlvoNameById: (state) => (id) => {
            if(!state.segmentoAlvo){
                return id
            }
            let obj = state.segmentoAlvo.find(g =>
                g.id == id
            )
            if (obj) {
                return obj.nome
            } else {
                return ""
            }
        }
    }
}
