import axios from 'axios'
export default {
    state: {
        contatos: []
    },
    mutations: {
        setContatos(state, payload) {
            state.contatos = payload
        }
    },
    actions: {
        setContatos(context, payload) {
            context.commit('setContatos', payload)
        },
        saveContato(context, payload) {
            const metodo = payload.id ? "patch" : "post";
            const finalUrl = payload.id ? `/${payload.id}` : "";
            axios[metodo](`/contatos/${finalUrl}`, payload).then((resp) => {
                if (resp.status < 300) {
                    context.dispatch("resetSnack");
                    context.dispatch("showSuccessSnack", "Contato salva com Sucesso");

                    let iniObs = metodo == "patch" ? "Atualizou o contato" : "Criou o contato";
                    let obj = {
                        observacao: `${iniObs} ${payload.nome} no cliente`,
                        cliente: payload.cliente,
                        metodo: null,
                        parametro: null
                    }
                    context.dispatch("saveHistorico", obj);
                }
            });
        },
        deletContato(context, payload) {
            axios.delete(`/contatos/${payload.id}`).then((resp) => {
                if (resp.status < 300) {
                    context.dispatch("resetSnack");
                    context.dispatch("showSuccessSnack", "Prospecção Deletada com Sucesso");
                    let obs = `
                        Deletou o contato <strong>${payload.nome}</strong> no cliente<br>
                        Informações do contato:<br>
                        ${payload.email ? `<strong>Email:</strong> ${payload.email}` : ""}<br>
                        ${payload.telefone ? `<strong>Telefone:</strong> ${payload.telefone}` : ""}<br>
                        ${payload.celular ? `<strong>Celular:</strong> ${payload.celular}` : ""}<br>
                        ${payload.cargo ? `<strong>Cargo:</strong> ${payload.cargo}` : ""}<br>
                        ${payload.whatsapp ? `<strong>Whatsapp:</strong> ${payload.whatsapp}` : ""}<br>
                    `;
                    let obj = {
                        observacao: obs,
                        cliente: payload.cliente,
                        metodo: null,
                        parametro: null
                    }
                    context.dispatch("saveHistorico", obj);
                }
            });
        },
        loadContatos(context, payload) {
            axios(`/contatosclientes/${payload}/`).then((resp) => {
                // console.log(resp.data);
                if (resp.status < 300) {
                    context.commit('setContatos', resp.data)
                }
            });
        },
        loadAllContatos(context, ids) {
            axios(`/contatosreport?idMinimo=${ids.idMinimo}&idMaximo=${ids.idMaximo}`).then((resp) => {
                // console.log(resp.data);
                if (resp.status < 300) {
                    context.commit('setContatos', resp.data)
                }
            });
        }
    },
    getters: {
        getContatos(state) {
            return state.contatos
        },
        getResponsavelByClienteId: (state) => (id) => {
            const contatos = state.contatos.filter(c => c.cliente == id)
            let contato = null
            if (contatos && contatos.length > 0) {
                contato = contatos.reduce((prev, current) => (prev.id > current.id) ? prev : current)
            }
            return contato
        }
    },
}