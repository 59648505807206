export default {
    state: {
    },
    mutations: {

    },
    actions: {

    },
    getters: {
        getIsoDateTimezaone: () => (date1) => {
            let date = new Date(date1);
            return new Date(
                date.getTime() - date.getTimezoneOffset() * 60000
            ).toISOString();
        },
        decodeHTML: () => (html) => {
            const txt = document.createElement('textarea');
            txt.innerHTML = html;
            return txt.value;
        },
        getLarguraTela: () => {
            return window.innerWidth;
        },
    }
}