import axios from 'axios'
import store from '../index'
export default {
    state: {
        usuarios: [],
    },
    mutations: {
        setUsuarios(state, payload) {
            state.usuarios = payload
        },
    },
    actions: {
        setUsuarios(context, payload) {
            context.commit('setUsuarios', payload)
        },
        loadUsuarios(context) {
            if (store.state.index.user.user.user_id === undefined) {
                // O usuário não está autenticado, então retornamos e evitamos a solicitação
                return;
            }

            axios(`/usuario/`).then((resp) => {
                if (resp.status < 300) {
                    // console.log(resp.data, 'usuarios');
                    context.commit('setUsuarios', resp.data)
                }
            }).then((resp) => {
                if (resp) {
                    if (resp.status < 300) {
                        // console.log(resp.data);
                        context.commit('setUsuarios', resp.data)
                        
                    }
                }
            });

        },
    },
    getters: {
        getUsuarios(state) {
            return state.usuarios
        },
        getUsuariosSelect: (state) => {
            let gp = []
            state.usuarios.forEach(element => {
                gp.push({ value: element.id, text: `${element.username}` })
            });
            // console.log(gp);
            return gp
        },
        getUsuarioById: (state) => (id) => {
            if (!state.usuarios) {
                return id
            }
            let obj = state.usuarios.find(g =>
                g.id == id
            )
            return obj
        },
        getUsuarioNameById: (state) => (id) => {
            if (!state.usuarios) {
                return id
            }
            let obj = state.usuarios.find(g =>
                g.id == id
            )
            if (obj) {                
                return obj.first_name + ' ' + obj.last_name
            } else {
                return ""

            }
        },
    },
}