import axios from "axios";
import { querybuilder } from "@/utils/querybuilder";

export default {
  state: {
    loading: false,
    reportOportunidades: [],
    reportAgendamentos: [],
    clientesAgendamentos: [],
  },
  mutations: {
    setLoading(state, payload) {
      state.loading = payload;
    },
    setReportOportunidades(state, payload) {
      state.reportOportunidades = payload;
    },
    setReportAgendamentos(state, payload) {
      state.reportAgendamentos = payload;
    },
    setClientesAgendamentos(state, payload) {
      state.clientesAgendamentos = payload;
    },
  },
  actions: {
    loadReportOportunidades(context, payload) {
      context.commit("setLoading", true);
      let filtro = context.getters.getFiltroPesquisa;
      filtro = { ...filtro, ...payload }
      const query = querybuilder(filtro);
      axios(`/relatoriooportunidades/${query}`).then((resp) => {
        if (resp.status < 300) {
          context.commit("setReportOportunidades", resp.data);
          if (filtro.estrelas) {
            context.dispatch("loadEstrelas");
          }
          else if (filtro.master) {
            const idsDeClientes = resp.data.map((item) => item.id);
            const idMinimo = Math.min(...idsDeClientes);
            const idMaximo = Math.max(...idsDeClientes);
            context.dispatch("loadEstrelas");
            context.dispatch("loadEtapasClientes");
            context.dispatch("loadAllContatos", { idMinimo, idMaximo });
          }
          context.commit("setLoading", false);
        }
      });
    },
    loadReportAgendamentos(context, payload) {
      context.commit("setLoading", true);
      let filtro = context.getters.getFiltroPesquisa;
      filtro = { ...filtro, ...payload }
      const query = querybuilder(filtro);
      axios(`/relatorioagendamento/${query}`).then((resp) => {
        if (resp.status < 300) {
          context.commit("setReportAgendamentos", resp.data);
          context.dispatch("loadTipoAgendamento")
          context.commit("setLoading", false);
        }
      });
    },
    loadClientesAgendamentos(context) {
      axios(`/nomeclienteagendamento/`).then((resp) => {
        if (resp.status < 300) {
          context.commit("setClientesAgendamentos", resp.data);
        }
      });
    }
  },
  getters: {
    getReportOportunidades(state) {
      return state.reportOportunidades;
    },
    getReportAgendamentos(state) {
      return state.reportAgendamentos;
    },
    getLoading(state) {
      return state.loading;
    },
    getClientesAgendamentos(state) {
      return state.clientesAgendamentos;
    },
  },
};
