import axios from 'axios'

export default {
  state: {

  },
  mutations: {

  },
  actions: {
    async saveHistorico(context, payload) {
      // Descobre o usuário logado
      let perfil = {};
      let user = context.getters.getUser.user.user_id;
      await axios.get("user/detail/" + user + "/")
        .then((resp) => {
          perfil = resp.data;
        })
      // Salva o histórico
      axios
        .post(`historico/`, {
          observacao: `${payload.observacao}`,
          uid: perfil.id,
          cliente: payload.cliente,
        })
        .then((resp) => {
          if (resp.status < 300) {
            if (payload.metodo !== null) {
              context.dispatch(payload.metodo, payload.parametro);
            }
            if (payload.callback) {
              if (payload.callback.func && typeof payload.callback.func === 'function') {
                payload.callback.func(payload.callback.params);
              }
            }
          }
        });
    },
  },
  getters: {

  }
}
