<template>
  <div>
    <v-card
      class="elevation-0"
      :style="{
        backgroundColor: this.$vuetify.theme.dark
          ? 'rgba(0, 0, 0, 0.6)'
          : 'rgba(255, 255, 255, 0.6)',
          overflow: 'auto',
          width: '100%',
      }"
    >
      <v-card-text class="text-center elevation-1">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xml:space="preserve"
          width="100%"
          height="204.107mm"
          version="1.1"
          style="
            shape-rendering: geometricPrecision;
            text-rendering: geometricPrecision;
            image-rendering: optimizeQuality;
            fill-rule: evenodd;
            clip-rule: evenodd;
          "
          viewBox="0 0 19588.98 20261.65"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          xmlns:xodm="http://www.corel.com/coreldraw/odm/2003"
        >
          <defs>
            <font
              id="FontID3"
              horiz-adv-x="602"
              font-variant="normal"
              style="fill-rule: nonzero"
              font-style="normal"
              font-weight="400"
            >
              <font-face font-family="DejaVu Sans Mono">
                <font-face-src>
                  <font-face-name name="DejaVu Sans Mono" />
                </font-face-src>
              </font-face>
              <missing-glyph><path d="M0 0z" /></missing-glyph>
              <glyph
                unicode="A"
                horiz-adv-x="602"
                d="M300.834 641.995l-104.168 -372.995 208.163 0 -103.995 372.995zm-59.6657 87.0093l119.668 0 223.163 -729.004 -101.999 0 -53.8304 189.995 -255.334 0 -52.8325 -189.995 -101.999 0 223.163 729.004z"
              />
              <glyph
                unicode="D"
                horiz-adv-x="602"
                d="M212.838 81.0005c82.9962,0 140.992,20.4996 173.834,61.3361 32.8319,40.8256 49.3292,114.668 49.3292,221.493 0,107.672 -16.3346,182.002 -49.0038,222.838 -32.8319,40.8365 -90.8273,61.3361 -174.16,61.3361l-46.8345 0 0 -567.003 46.8345 0zm1.99573 648.004c111.338,0 193.336,-29.6756 245.995,-88.8424 52.8434,-59.3295 79.1674,-151.328 79.1674,-276.332 0,-124.332 -26.3241,-216.168 -79.1674,-275.161 -52.659,-59.1668 -134.657,-88.6688 -245.995,-88.6688l-147.836 0 0 729.004 147.836 0z"
              />
              <glyph
                unicode="E"
                horiz-adv-x="602"
                d="M96.001 729.004l432.997 0 0 -83.007 -334.002 0 0 -215.994 319.002 0 0 -83.007 -319.002 0 0 -264 343.005 0 0 -82.9962 -441.999 0 0 729.004z"
              />
              <glyph
                unicode="I"
                horiz-adv-x="602"
                d="M97.9967 729.004l405.002 0 0 -83.007 -152.998 0 0 -563.001 152.998 0 0 -82.9962 -405.002 0 0 82.9962 152.998 0 0 563.001 -152.998 0 0 83.007z"
              />
              <glyph
                unicode="N"
                horiz-adv-x="602"
                d="M67.9957 729.004l124.841 0 246.158 -600.171 0 600.171 95.0031 0 0 -729.004 -124.831 0 -246.169 600.171 0 -600.171 -95.0031 0 0 729.004z"
              />
              <glyph
                unicode="O"
                horiz-adv-x="602"
                d="M441.999 363.83c0,106.999 -10.9982,183.498 -32.9946,229.335 -22.0072,45.8366 -58.0062,68.8309 -108.171,68.8309 -50.0016,0 -85.8379,-22.9942 -107.834,-68.8309 -21.9964,-45.8366 -32.9946,-122.336 -32.9946,-229.335 0,-106.826 10.9982,-183.162 32.9946,-228.999 21.9964,-45.8258 57.8327,-68.8309 107.834,-68.8309 50.1643,0 86.1633,22.8315 108.171,68.4946 21.9964,45.8366 32.9946,122.173 32.9946,229.335zm102.997 0c0,-127 -20.1633,-221.667 -60.3273,-284.163 -40.164,-62.4966 -101.5,-93.669 -183.835,-93.669 -82.4973,0 -143.671,30.9988 -183.672,93.1701 -40.164,62.1712 -60.1646,157.001 -60.1646,284.662 0,127.173 20.1742,222.003 60.3382,284.499 40.164,62.5075 101.327,93.669 183.498,93.669 82.3346,0 143.671,-31.1615 183.835,-93.669 40.164,-62.4966 60.3273,-157.326 60.3273,-284.499z"
              />
              <glyph
                unicode="P"
                horiz-adv-x="602"
                d="M194.995 648.004l0 -274 114.169 0c45.5004,0 81.0005,11.9961 106.5,36.1617 25.4997,24.003 38.331,57.8327 38.331,101.164 0,43.1684 -12.6577,76.8355 -37.9947,100.838 -25.3371,23.8294 -60.9998,35.8363 -106.836,35.8363l-114.169 0zm-98.9945 81.0005l213.163 0c81.3367,0 142.998,-18.5039 184.832,-55.338 42.0079,-36.9969 63.0064,-91.1635 63.0064,-162.337 0,-71.998 -20.8358,-126.327 -62.6702,-163.161 -41.8343,-36.8342 -103.496,-55.1645 -185.169,-55.1645l-114.169 0 0 -293.003 -98.9945 0 0 729.004z"
              />
              <glyph
                unicode="R"
                horiz-adv-x="602"
                d="M371.162 344.165c25.3371,-6.49696 47.008,-18.8292 64.8394,-36.8342 17.9941,-18.1676 40.3375,-54.1666 66.9978,-108.16l99.0054 -199.171 -105.839 0 -86.9985 183.997c-25.0008,52.3336 -47.4961,86.1742 -67.6703,101.337 -20.0007,15.1632 -45.9993,22.6689 -78.3323,22.6689l-94.1679 0 0 -308.004 -98.9945 0 0 729.004 203 0c80.0026,0 141.328,-18.0049 183.997,-54.1666 42.6695,-36.1725 64.0043,-88.5061 64.0043,-156.838 0,-48.1686 -13.1675,-87.4974 -39.3397,-117.997 -26.1614,-30.3372 -62.9955,-49.0038 -110.503,-55.837zm-202.165 303.839l0 -259 107.834 0c47.1707,0 82.3346,10.662 105.502,31.834 23.1678,21.1612 34.6649,53.4941 34.6649,97.1615 0,41.997 -12.3323,74.1673 -36.8342,96.4999 -24.6646,22.3326 -60.3273,33.5043 -107.162,33.5043l-104.006 0z"
              />
              <glyph
                unicode="S"
                horiz-adv-x="602"
                d="M493.997 704.003l0 -100.003c-30.001,19.1655 -60.002,33.667 -89.9921,43.3311 -30.1745,9.83763 -60.5009,14.6643 -91.1743,14.6643 -46.4983,0 -83.3324,-10.8246 -110.329,-32.4956 -26.9965,-21.4974 -40.5002,-50.8368 -40.5002,-87.4974 0,-32.1702 8.82892,-56.8348 26.6603,-73.6683 17.6687,-16.9962 50.8368,-30.9988 99.3416,-42.496l51.8347 -11.6707c68.3319,-15.9984 118.16,-40.9992 149.332,-75.1651 31.1615,-34.166 46.8345,-80.6642 46.8345,-139.668 0,-69.3298 -21.5083,-122.162 -64.5032,-158.671 -43.0057,-36.4979 -105.502,-54.6656 -187.501,-54.6656 -34.166,0 -68.5055,3.66606 -102.997,10.9982 -34.5022,7.34297 -69.1671,18.3412 -104.006,33.0054l0 105.003c37.5066,-23.8403 72.8332,-41.3354 106.338,-52.3336 33.3308,-11.1717 66.9978,-16.6708 100.828,-16.6708 49.8389,0 88.6688,11.1717 116.338,33.5043 27.669,22.3326 41.4981,53.4941 41.4981,93.4954 0,36.4979 -9.50139,64.3296 -28.6669,83.4951 -19.0028,19.1763 -52.1709,34.0033 -99.3308,44.5025l-52.8325 12.1696c-67.6703,15.3367 -116.674,38.331 -147.337,69.3298 -30.4999,30.8361 -45.8366,72.3342 -45.8366,124.332 0,65.0021 21.8337,117.173 65.6746,156.339 43.6674,39.1661 101.825,58.8305 174.333,58.8305 27.9944,0 57.4964,-3.16713 88.3326,-9.50139 30.9988,-6.33426 63.4945,-15.8248 97.6604,-28.4933z"
              />
              <glyph
                unicode="T"
                horiz-adv-x="602"
                d="M23.0051 729.004l555.994 0 0 -83.007 -228.001 0 0 -645.997 -98.9945 0 0 645.997 -228.999 0 0 83.007z"
              />
              <glyph
                unicode="U"
                horiz-adv-x="602"
                d="M71.998 279.836l0 449.169 99.0054 0 0 -494.007c0,-35.5001 0.997863,-60.8263 2.99359,-76.0003 1.83303,-15.1632 5.3364,-26.8338 10.1739,-34.8276 10.3257,-19.339 25.4997,-33.8406 45.1642,-43.5047 19.6644,-9.82678 43.4938,-14.6643 71.4991,-14.6643 28.1679,0 52.1709,4.83747 71.6618,14.6643 19.5017,9.66409 34.6757,24.1656 45.3377,43.5047 4.83747,7.99375 8.32999,19.6644 10.163,34.6649 2.00657,14.8378 3.00444,40.0013 3.00444,75.1651l0 495.005 98.9945 0 0 -449.169c0,-74.5035 -4.66393,-127.499 -13.8291,-158.834 -9.32785,-31.4978 -25.3371,-57.3338 -48.1686,-77.8333 -21.4974,-19.339 -45.9993,-33.5043 -73.6683,-43.0057 -27.6582,-9.50139 -58.8305,-14.1653 -93.3328,-14.1653 -34.166,0 -65.1648,4.66393 -92.8338,14.1653 -27.6582,9.50139 -52.3336,23.6667 -74.1673,43.0057 -22.4953,20.1633 -38.331,46.162 -47.8324,78.3323 -9.50139,31.9967 -14.1653,84.8292 -14.1653,158.335z"
              />
            </font>
            <font
              id="FontID1"
              horiz-adv-x="692"
              font-variant="normal"
              style="fill-rule: nonzero"
              font-weight="400"
            >
              <font-face font-family="Microsoft JhengHei">
                <font-face-src>
                  <font-face-name name="Microsoft JhengHei" />
                </font-face-src>
              </font-face>
              <missing-glyph><path d="M0 0z" /></missing-glyph>
              <glyph
                unicode="N"
                horiz-adv-x="805"
                d="M704.998 0l-104.331 0 -394.666 610.825c-8.65833,14.3369 -16.4921,29.517 -23.3325,45.5031l-2.99856 0c2.66122,-15.6675 3.99183,-49.4949 3.99183,-101.67l0 -554.658 -83.4911 0 0 756.33 110.328 0 383.665 -604.49c19.678,-31.1663 29.8356,-48.5017 30.8289,-51.6689l2.00528 0c-3.67323,25.0005 -5.49111,62.9884 -5.49111,113.664l0 542.495 83.4911 0 0 -756.33z"
              />
              <glyph
                unicode="O"
                horiz-adv-x="813"
                d="M50.3383 369.16c0,121.348 33.0029,218.332 98.8212,291.01 66.0057,72.6588 155.007,108.829 267.34,108.829 104.162,0 188.01,-35.4954 251.673,-106.336 63.6631,-70.9908 95.4853,-162.503 95.4853,-274.499 0,-120.654 -32.1595,-217.826 -96.4973,-290.991 -64.1691,-73.1648 -151.989,-109.841 -263.33,-109.841 -106.505,0 -192.001,35.6641 -256.658,106.842 -64.5065,71.3282 -96.8346,162.99 -96.8346,274.986zm87.333 7.83373c0,-90.8188 24.1571,-165.989 72.3214,-225.323 48.1831,-59.5026 112.671,-89.1696 193.332,-89.1696 86.3397,0 153.339,27.999 201.334,84.1657 48.1831,56.1667 72.1715,132.499 72.1715,228.827 0,99.6645 -23.3325,177.346 -69.6602,233.344 -46.3277,55.9981 -111.996,83.9971 -197.005,83.9971 -82.6665,0 -148.841,-29.8356 -198.167,-89.5069 -49.4949,-59.8212 -74.3267,-135.16 -74.3267,-226.335z"
              />
              <glyph
                unicode="a"
                horiz-adv-x="547"
                d="M100.995 501.509c53.1682,34.1648 113.833,51.1629 182.162,51.1629 125.34,0 188.01,-66.5117 188.01,-199.666l0 -353.005 -81.5045 0 0 85.0091 -2.99856 0c-37.332,-65.1811 -92.6554,-97.678 -165.989,-97.678 -53.3368,0 -95.504,14.1682 -126.183,42.6732 -30.8289,28.505 -46.159,66.1557 -46.159,112.989 0,100.002 59.3339,158.511 177.664,175.341l163.178 23.5012c0,93.6674 -37.5007,140.67 -112.333,140.67 -65.3498,0 -124.009,-22.3392 -175.847,-66.999l0 86.0024zm155.831 -245.17c-46.159,-6.16578 -78.3186,-17.3354 -96.4973,-33.5089 -17.9913,-16.0048 -26.987,-40.8366 -26.987,-74.3267 0,-27.0058 9.65161,-49.0077 28.9923,-66.0057 19.3407,-16.8294 44.6598,-25.3378 75.9947,-25.3378 43.1792,0 79.1619,15.4988 107.836,46.3464 28.6737,30.9976 43.0106,69.4916 43.0106,115.669l0 54.8174 -132.349 -17.654z"
              />
              <glyph
                unicode="c"
                horiz-adv-x="497"
                d="M454.169 25.3378c-41.9985,-25.3378 -90.8375,-38.0067 -146.498,-38.0067 -76.5007,0 -138.346,25.0005 -185.33,75.0014 -47.0024,49.8323 -70.5036,114.826 -70.5036,195 0,89.5069 25.3378,161.172 76.1633,214.828 50.6569,53.6742 118.331,80.5113 202.496,80.5113 46.665,0 88.1763,-8.67707 124.496,-26.3311l0 -85.3464c-40.3306,28.1677 -83.3224,42.3359 -129.332,42.3359 -55.3234,0 -100.995,-19.828 -136.997,-59.6713 -35.8328,-39.9933 -53.8241,-92.9927 -53.8241,-158.998 0,-64.4877 16.6607,-115.332 50.1509,-152.327 33.6775,-37.1634 78.6746,-55.6607 135.347,-55.6607 47.827,0 92.4867,15.8361 133.829,47.321l0 -78.6559z"
              />
              <glyph
                unicode="d"
                horiz-adv-x="633"
                d="M545.006 0l-81.1672 0 0 92.8241 -1.83662 0c-40.1619,-70.3162 -102.344,-105.493 -186.66,-105.493 -68.3484,0 -122.678,24.3445 -163.009,72.9961 -40.3306,48.6703 -60.4959,114.339 -60.4959,197.005 0,88.4949 22.4892,159.842 67.8236,214.172 45.1658,54.1614 105.006,81.1672 179.164,81.1672 74.1768,0 128.507,-29.3296 163.178,-88.345l1.83662 0 0 335.014 81.1672 0 0 -799.34zm-81.1672 325.175c0,44.3224 -15.0115,81.6545 -45.1658,112.333 -30.0043,30.6602 -66.999,45.8217 -110.516,45.8217 -54.1614,0 -96.4973,-19.828 -127.326,-59.6713 -30.6602,-39.9933 -45.9904,-93.8174 -45.9904,-161.997 0,-62.1638 14.6554,-111.827 44.1538,-149.159 29.3296,-37.1634 69.0043,-55.8294 118.837,-55.8294 47.827,0 87.5016,17.4853 119.005,52.4935 31.3349,35.0082 47.0024,79.6679 47.0024,133.998l0 82.0105z"
              />
              <glyph
                unicode="e"
                horiz-adv-x="563"
                d="M518.994 250.005l-382.822 0c1.66795,-62.1638 18.16,-109.841 49.3263,-143.012 31.3349,-33.3215 74.1768,-49.8323 128.507,-49.8323 60.8332,0 117,20.334 168.5,61.0019l0 -78.1686c-47.3397,-35.1581 -109.841,-52.6622 -187.504,-52.6622 -75.3387,0 -134.673,24.4945 -178.002,73.6708 -43.4978,49.1576 -65.1624,118.331 -65.1624,207.5 0,82.1605 23.5012,150.003 70.5036,203.658 46.9836,53.6742 105.83,80.5113 176.484,80.5113 69.3416,0 123.334,-22.6766 162.166,-68.3296 38.6814,-45.5031 58.0033,-109.016 58.0033,-190.333l0 -44.0038zm-83.9971 71.8342c-0.337338,50.6569 -12.5002,90.1629 -36.4887,118.331 -24.1759,28.1677 -57.8347,42.3359 -101.183,42.3359 -39.3186,0 -73.9894,-14.1682 -103.656,-42.5045 -29.8356,-28.3364 -48.6703,-67.8424 -56.5041,-118.162l297.832 0z"
              />
              <glyph
                unicode="g"
                horiz-adv-x="633"
                d="M545.006 42.5045c0,-197.998 -93.8361,-296.838 -281.34,-296.838 -66.3243,0 -124.834,12.8376 -175.341,38.494l0 83.0038c61.0019,-33.8275 119.174,-50.8255 174.835,-50.8255 133.848,0 200.678,71.8342 200.678,215.334l0 60.1585 -1.83662 0c-42.3359,-69.6602 -104.5,-104.5 -186.66,-104.5 -67.6737,0 -121.835,24.1759 -162.503,72.6775 -40.6679,48.4829 -61.0019,112.652 -61.0019,192.489 0,91.9995 22.1706,165.164 66.8303,219.176 44.6598,53.9928 104.668,80.9985 180.157,80.9985 73.1835,0 127.682,-29.3296 163.178,-88.345l1.83662 0 0 75.6761 81.1672 0 0 -497.498zm-81.1672 282.67c0,43.9851 -15.1802,81.3171 -45.3344,112.165 -30.3416,30.6602 -66.8303,45.9904 -109.504,45.9904 -53.9928,0 -96.4973,-19.828 -127.664,-59.5026 -30.9976,-39.6559 -46.4964,-94.8294 -46.4964,-165.502 0,-61.1518 14.8241,-110.159 44.8284,-146.817 30.0043,-36.5074 68.8356,-54.8361 116.663,-54.8361 48.5017,0 88.6636,17.4853 120.167,52.4935 31.6723,35.0082 47.3397,79.6679 47.3397,133.998l0 82.0105z"
              />
              <glyph
                unicode="i"
                horiz-adv-x="259"
                d="M77.6626 734.328c0,15.3301 5.17251,28.3364 15.3301,38.8313 10.345,10.6824 22.6766,15.8361 37.332,15.8361 15.3489,0 28.3364,-5.32244 38.8501,-15.9861 10.6636,-10.851 15.8174,-23.6699 15.8174,-38.6814 0,-14.3369 -5.15377,-26.8371 -15.8174,-37.5007 -10.5137,-10.8323 -23.5012,-16.1547 -38.8501,-16.1547 -15.3301,0 -27.8304,5.15377 -37.8193,15.6675 -9.83901,10.3263 -14.8429,22.9952 -14.8429,37.988zm11.1696 -734.328l0 540.003 81.1672 0 0 -540.003 -81.1672 0z"
              />
              <glyph
                unicode="n"
                horiz-adv-x="608"
                d="M532.169 0l-81.5045 0 0 308.664c0,116.494 -42.6732,174.666 -127.832,174.666 -44.6598,0 -81.3359,-16.6607 -109.991,-50.0009 -28.6737,-33.3215 -42.8419,-75.1701 -42.8419,-125.171l0 -308.158 -81.6732 0 0 540.003 81.6732 0 0 -90.3315 2.34262 0c40.3306,68.667 99.4959,103 177.327,103 59.1653,0 104.5,-19.0034 135.666,-57.0101 31.3349,-38.1566 46.8337,-93.3301 46.8337,-165.67l0 -329.991z"
              />
              <glyph
                unicode="o"
                horiz-adv-x="630"
                d="M51.8376 263.161c0,89.8443 24.4945,160.666 73.5021,212.167 48.8203,51.6689 115.501,77.344 199.835,77.344 79.4992,0 141.663,-24.8318 186.829,-74.6641 44.9971,-49.8323 67.6549,-118.668 67.6549,-206.507 0,-85.3277 -24.4945,-153.995 -73.3335,-206.001 -48.8203,-52.1749 -113.495,-78.1686 -193.819,-78.1686 -77.8313,0 -140.67,25.0005 -188.665,75.1701 -48.0144,50.1696 -72.0028,117 -72.0028,200.66zm83.0038 3.50456c0,-63.8318 16.6607,-114.826 50.0009,-152.833 33.3215,-38.1754 77.8313,-57.16 133.492,-57.16 56.6727,0 100.508,18.4973 131.674,55.6607 30.9976,36.9947 46.4964,89.1696 46.4964,156.169 0,68.667 -15.3301,121.666 -46.0091,158.998 -30.8289,37.332 -74.8327,55.8294 -132.161,55.8294 -55.9981,0 -100.508,-19.172 -133.829,-57.8347 -33.1715,-38.494 -49.6636,-91.4935 -49.6636,-158.83z"
              />
              <glyph
                unicode="p"
                horiz-adv-x="632"
                d="M169.999 79.1619l0 -327.667 -81.6732 0 0 788.508 81.6732 0 0 -95.6727 2.34262 0c42.6545,72.3402 104.987,108.342 186.998,108.342 68.667,0 122.997,-24.0072 162.653,-72.0028 39.6747,-47.9957 59.5026,-113.008 59.5026,-195 0,-89.8443 -22.1706,-161.997 -66.6617,-216.496 -44.3412,-54.6674 -104.5,-81.8419 -180.326,-81.8419 -70.3349,0 -124.346,30.5103 -162.166,91.8308l-2.34262 0zm0 139.677c0,-45.3344 14.9928,-83.6785 45.3344,-115.013 30.3416,-31.4848 67.505,-47.1523 111.827,-47.1523 52.9995,0 94.8294,20.5026 125.171,61.4892 30.5103,41.0053 45.6718,97.678 45.6718,169.999 0,60.8332 -14.3369,108.51 -43.0106,143.181 -28.4863,34.6521 -66.6617,51.9875 -114.151,51.9875 -49.5137,0 -90.3503,-17.5041 -122.51,-52.4935 -32.3282,-34.8395 -48.333,-79.6679 -48.333,-133.998l0 -78z"
              />
              <glyph
                unicode="r"
                horiz-adv-x="372"
                d="M365.674 456.493c-14.8429,11.507 -36.5074,17.1667 -64.8438,17.1667 -38.1566,0 -69.4916,-18.16 -94.0048,-54.4988 -24.6631,-36.32 -36.826,-83.8284 -36.826,-142.825l0 -276.336 -81.6732 0 0 540.003 81.6732 0 0 -111.827 2.34262 0c12.3316,38.494 31.3162,68.3296 56.654,89.3195 25.3378,21.1773 53.3368,31.8409 83.9971,31.8409 23.1826,0 40.6679,-2.51129 52.6809,-7.32772l0 -85.5151z"
              />
              <glyph
                unicode="t"
                horiz-adv-x="362"
                d="M334.002 5.34118c-19.828,-11.3383 -46.0091,-17.0168 -78.6746,-17.0168 -92.6554,0 -139.152,52.1749 -139.152,156.675l0 324.669 -93.6674 0 0 70.3349 93.6674 0 0 132.836 81.1672 26.3311 0 -159.167 136.659 0 0 -70.3349 -136.659 0 0 -309.995c0,-36.8448 6.16578,-63.0072 18.4973,-78.6746 12.3316,-15.4988 32.9841,-23.3325 61.9952,-23.3325 22.4892,0 41.1739,5.99711 56.1667,17.9913l0 -70.3162z"
              />
              <glyph
                unicode="u"
                horiz-adv-x="608"
                d="M520.493 0l-81.4858 0 0 85.4964 -2.00528 0c-35.4954,-65.4997 -91.0062,-98.1653 -166.495,-98.1653 -128.844,0 -193.332,76.6694 -193.332,230.008l0 322.663 80.4925 0 0 -309.001c0,-116.344 44.3412,-174.329 132.836,-174.329 43.6665,0 79.3306,16.1547 106.992,48.333 27.6617,32.1595 41.5113,73.5021 41.5113,123.99l0 311.007 81.4858 0 0 -540.003z"
              />
              <glyph
                unicode="ó"
                horiz-adv-x="630"
                d="M51.8376 263.161c0,89.8443 24.4945,160.666 73.5021,212.167 48.8203,51.6689 115.501,77.344 199.835,77.344 79.4992,0 141.663,-24.8318 186.829,-74.6641 44.9971,-49.8323 67.6549,-118.668 67.6549,-206.507 0,-85.3277 -24.4945,-153.995 -73.3335,-206.001 -48.8203,-52.1749 -113.495,-78.1686 -193.819,-78.1686 -77.8313,0 -140.67,25.0005 -188.665,75.1701 -48.0144,50.1696 -72.0028,117 -72.0028,200.66zm83.0038 3.50456c0,-63.8318 16.6607,-114.826 50.0009,-152.833 33.3215,-38.1754 77.8313,-57.16 133.492,-57.16 56.6727,0 100.508,18.4973 131.674,55.6607 30.9976,36.9947 46.4964,89.1696 46.4964,156.169 0,68.667 -15.3301,121.666 -46.0091,158.998 -30.8289,37.332 -74.8327,55.8294 -132.161,55.8294 -55.9981,0 -100.508,-19.172 -133.829,-57.8347 -33.1715,-38.494 -49.6636,-91.4935 -49.6636,-158.83zm309.151 529.17l-136.828 -170.337 -63.0072 0 116.344 170.337 83.4911 0z"
              />
            </font>
            <font
              id="FontID0"
              horiz-adv-x="718"
              font-variant="normal"
              style="fill-rule: nonzero"
              font-style="normal"
              font-weight="700"
            >
              <font-face font-family="Microsoft JhengHei">
                <font-face-src>
                  <font-face-name name="Microsoft JhengHei Negrito" />
                </font-face-src>
              </font-face>
              <missing-glyph><path d="M0 0z" /></missing-glyph>
              <glyph
                unicode="0"
                horiz-adv-x="595"
                d="M42.4957 363.829c0,132.671 22.6745,233.508 68.1663,302.169 45.3395,68.6703 111.176,103.006 197.508,103.006 164.333,0 246.5,-128.999 246.5,-387.169 0,-126.336 -22.5033,-223.664 -67.3388,-292.001 -44.9971,-68.3279 -109.996,-102.501 -195.33,-102.501 -82.3378,0 -144.503,32.1666 -186.504,96.6711 -42.0011,64.495 -63.0017,157.666 -63.0017,279.827zm116.673 4.33707c0,-192.667 46.6616,-288.996 140.165,-288.996 92.1628,0 138.168,98.326 138.168,294.826 0,201.836 -45.0067,302.834 -135.172,302.834 -95.5012,0 -143.161,-102.996 -143.161,-308.665z"
              />
              <glyph
                unicode="1"
                horiz-adv-x="595"
                d="M537.168 0l-445.336 0 0 95.6654 165.501 0 0 546.499 -170.001 -46.0002 0 100.671 284.665 74.6662 0 -675.836 165.171 0 0 -95.6654z"
              />
              <glyph
                unicode="2"
                horiz-adv-x="595"
                d="M523.502 0l-470.333 0 0 95.6633 219.334 215.839c48.8288,47.8283 84.8335,89.1668 108.163,123.995 23.1671,34.8352 34.8352,72.8342 34.8352,113.835 0,83.6708 -44.171,125.503 -132.838,125.503 -66.3309,0 -130.161,-27.8384 -191.333,-83.5018l0 110.333c61.5041,45.0025 132.168,67.3314 211.837,67.3314 69.3324,0 124.665,-18.4959 165.834,-55.3321 41.1628,-36.9985 61.8354,-87.497 61.8354,-151.665 0,-46.1653 -13.1688,-92.1683 -39.1685,-138.002 -25.9997,-45.665 -71.3334,-99.8344 -136.17,-162.332l-167.997 -160.169 0 -2.33226 336.001 0 0 -99.1651z"
              />
              <glyph
                unicode="3"
                horiz-adv-x="595"
                d="M72.3352 138.169c51.6661,-39.3373 110.332,-59.0027 175.667,-59.0027 48.4997,0 87.3317,11.6685 116.328,35.3356 28.8344,23.6671 43.3391,55.6678 43.3391,95.9955 0,88.8341 -64.6685,133.339 -194.336,133.339l-64.3316 0 0 92.1622 60.4983 0c115.499,0 173.33,41.4999 173.33,124.5 0,77.5025 -44.161,116.335 -132.328,116.335 -52.1713,0 -101.837,-17.6644 -149.501,-52.8316l0 102.497c49.8336,28.3358 108.83,42.5037 176.833,42.5037 65.167,0 118.335,-16.4989 159.835,-49.5035 41.4999,-32.9978 62.3307,-77.4957 62.3307,-133.5 0,-99.3303 -50.5006,-162.665 -151.333,-189.997l0 -2.00089c53.3301,-5.16728 96.164,-24.334 128.333,-57.3319 32.3309,-33.0046 48.3312,-73.6691 48.3312,-121.832 0,-70.3343 -25.3311,-125.834 -76.3302,-166.505 -50.9991,-40.6645 -117.332,-60.9968 -199.004,-60.9968 -73.8308,0 -133.164,13.1641 -177.661,39.499l0 111.336z"
              />
              <glyph
                unicode="8"
                horiz-adv-x="595"
                d="M209.002 397.503c-89.5011,39.9976 -134.336,99.8289 -134.336,179.662 0,57.6687 22.3332,103.999 66.9995,139.166 44.4979,35.1671 99.6672,52.6699 165.501,52.6699 61.5021,0 113.168,-16.3372 154.998,-49.3351 41.8368,-32.8361 62.6676,-76.6671 62.6676,-131.331 0,-83.3367 -47.1657,-146.833 -141.497,-190.334l0 -2.00089c109.328,-39.0005 163.999,-105.832 163.999,-200.17 0,-66.5009 -23.8355,-117.83 -71.6682,-154.001 -48.0011,-36.3326 -111.834,-54.4956 -191.998,-54.4956 -67.6664,0 -123.671,18.163 -168.001,54.664 -44.1678,36.5011 -66.3325,85.0007 -66.3325,145.499 0,94.6683 53.1617,164.006 159.667,208.005l0 2.00089zm203.168 179.164c0,31.5022 -10.0044,56.4965 -30.1683,74.9963 -20.0022,18.3381 -46.6672,27.5004 -80.1703,27.5004 -32.0008,0 -58.8342,-9.66086 -80.8305,-29.3329 -22.003,-19.4969 -32.9978,-43.6625 -32.9978,-72.6653 0,-59.3328 37.1613,-103.999 111.329,-134.329 75.1715,31.3271 112.838,75.8317 112.838,133.831zm-119.171 -230.5c-87.6686,-34.837 -131.331,-85.6677 -131.331,-152.836 0,-35.1671 12.9957,-63.3345 39.0005,-84.6639 25.9981,-21.3361 59.3328,-32.0008 99.6672,-32.0008 39.3306,0 71.6615,10.3346 96.8309,31.1654 25.3311,20.8375 37.835,48.8365 37.835,84.0037 0,69.3305 -47.3342,120.828 -142.003,154.331z"
              />
              <glyph
                unicode="9"
                horiz-adv-x="595"
                d="M88.3356 116.166c41.9985,-24.6642 87.8303,-36.9996 137.334,-36.9996 65.9957,0 116.833,25.001 152.33,75.1647 35.3356,50.1704 53.1684,120.336 53.1684,210.834 -1.00381,0 -1.83246,0.168425 -2.49943,0.498538 -32.8361,-59.1643 -87.1701,-88.8341 -163.002,-88.8341 -64.1699,0 -116.665,22.3399 -157.498,67.0062 -40.833,44.4979 -61.3337,100.327 -61.3337,167.334 0,75.6633 23.1618,137.495 69.6673,185.665 46.3304,48.1628 106.829,72.1668 181.333,72.1668 78.4995,0 139.328,-30.1683 182.33,-90.835 43.164,-60.4983 64.8369,-146.503 64.8369,-257.832 0,-135.333 -27.3387,-241.333 -81.6727,-318 -54.3272,-76.6671 -132.166,-115.001 -233.329,-115.001 -54.3339,0 -101.5,8.83221 -141.666,26.3349l0 102.497zm72.8337 407.831c0,-46.9973 11.9986,-84.3338 35.8274,-112.164 24.0039,-27.8306 57.0018,-41.6684 99.3371,-41.6684 36.8312,0 67.498,12.6656 92.1689,38.0034 24.4957,25.4995 36.8312,56.4965 36.8312,93.3344 0,48.4997 -12.167,89.8312 -36.3326,123.995 -24.334,34.1701 -57.0018,51.3359 -98.0032,51.3359 -37.9967,0 -69.1621,-14.8349 -93.4961,-44.4979 -24.1656,-29.6698 -36.3326,-65.834 -36.3326,-108.338z"
              />
              <glyph
                unicode="C"
                horiz-adv-x="670"
                d="M619.173 29.8403c-55.9977,-28.3492 -126.511,-42.5058 -211.505,-42.5058 -109.337,0 -197.007,34.3316 -262.832,103.174 -66.0043,68.9866 -99.0065,159.819 -99.0065,272.82 0,117.834 36.6671,214.829 109.678,291.163 73.1545,76.3344 167.328,114.511 282.486,114.511 72.5078,0 133.015,-10.0066 181.18,-30.1637l0 -118.678c-53.3388,29.8403 -112.498,44.8413 -177.335,44.8413 -82.3348,0 -148.339,-27.0018 -198.175,-81.3287 -49.8356,-54.1652 -74.6636,-125.002 -74.6636,-212.511 0,-83.6642 23.3369,-151.501 69.9926,-203.169 46.6737,-51.8298 109.337,-77.6638 187.845,-77.6638 72.8311,0 137.003,16.8334 192.336,50.3386l0 -110.828z"
              />
              <glyph
                unicode="D"
                horiz-adv-x="771"
                d="M94.6667 0l0 756.336 229.501 0c267.003,0 400.504,-122.835 400.504,-368.672 0,-118.163 -38.0052,-212.325 -114.005,-282.499 -76,-70.1627 -173.501,-105.165 -292.336,-105.165l-223.664 0zm117.333 655.832l0 -555.16 103.003 0c89.1654,0 159.16,24.8294 210.163,74.8241 51.0026,50.0052 76.336,119.843 76.336,209.837 0,88.168 -24.168,155.339 -72.6719,201.333 -48.4934,46.0052 -118.824,69.1654 -210.992,69.1654l-105.837 0z"
              />
              <glyph
                unicode="G"
                horiz-adv-x="750"
                d="M679.671 51.3318c-75.4994,-42.6557 -162.495,-64.0031 -261.163,-64.0031 -113.004,0 -203.349,34.3322 -271.014,102.996 -67.6655,68.6839 -101.665,161.183 -101.665,277.34 0,117.509 37.6812,213.827 112.671,288.837 75.0098,74.9902 171.661,112.495 289.835,112.495 81.3357,0 150.999,-12.4951 208.833,-37.5049l0 -120.153c-59.4986,36.8194 -131.669,55.1508 -216.177,55.1508 -79.8277,0 -145.163,-26.8312 -195.828,-80.4935 -50.8226,-53.8386 -76.1653,-124.168 -76.1653,-211.496 0,-89.8355 23.3451,-159.832 69.9961,-210.165 46.6706,-50.3329 110.008,-75.4994 189.679,-75.4994 52.8202,0 97.1602,9.83157 133.333,29.338l0 189.483 -160.674 0 0 99.5104 278.339 0 0 -355.836z"
              />
              <glyph
                unicode="L"
                horiz-adv-x="523"
                d="M507.332 0l-412.662 0 0 756.334 117.326 0 0 -655.672 295.336 0 0 -100.662z"
              />
              <glyph
                unicode="M"
                horiz-adv-x="992"
                d="M781.336 0l0 488.327c0,42.6753 2.17391,93.1649 6.8351,151.841l-2.99647 0c-9.83157,-42.0094 -17.5088,-69.5065 -23.0121,-82.5108l-230.004 -557.658 -76.6549 0 -230.846 553.173c-5.81669,14.0031 -13.4939,42.9886 -22.9926,86.9957l-2.99647 0c3.32942,-45.6718 4.99412,-100.666 4.99412,-165.002l0 -475.166 -108.989 0 0 756.326 163.651 0 204.505 -499.491c16.0008,-38.9933 26.6745,-71.5041 31.8449,-97.1602l3.32942 0c6.16921,20.4857 17.6655,53.3294 34.1559,98.6486l208.01 498.002 157.325 0 0 -756.326 -116.157 0z"
              />
              <glyph
                unicode="N"
                horiz-adv-x="823"
                d="M729.005 0l-130.846 0 -361.829 557.658c-13.3373,20.5053 -23.1688,38.1708 -29.338,53.1727l-2.82021 0c2.82021,-19.5065 4.32824,-56.3259 4.32824,-110.321l0 -500.509 -113.827 0 0 756.326 138.66 0 351.175 -546.827c16.4904,-25.989 27.6537,-44.1637 33.1571,-54.1716l2.50685 0c-3.68194,23.3451 -5.34665,59.4986 -5.34665,108.343l0 492.656 114.179 0 0 -756.326z"
              />
              <glyph
                unicode="O"
                horiz-adv-x="816"
                d="M45.8284 368.664c0,119.506 33.5096,216.001 100.333,289.835 66.9996,73.6584 157.168,110.497 270.838,110.497 106.502,0 191.833,-35.8402 256.326,-107.325 64.5123,-71.6804 96.6706,-163.514 96.6706,-275.499 0,-118.664 -32.8241,-215.002 -98.6682,-288.504 -65.6678,-73.5018 -154.485,-110.341 -266.49,-110.341 -106.835,0 -193.341,35.5072 -259.499,106.502 -66.3337,70.8382 -99.5104,162.495 -99.5104,274.834zm123.169 8.34313c0,-83.3333 21.3278,-152.017 63.8269,-205.836 42.6753,-53.8386 100.333,-80.8461 173.012,-80.8461 76.1653,0 135.331,25.6757 177.673,76.8508 42.3228,51.3318 63.4939,120.662 63.4939,207.814 0,89.1696 -20.5053,159.851 -61.3396,212.005 -40.8343,51.9976 -99.0012,78.0063 -174.501,78.0063 -73.6584,0 -132.335,-27.0074 -176.322,-81.0027 -43.8504,-53.9953 -65.8441,-122.993 -65.8441,-206.992z"
              />
              <glyph
                unicode="P"
                horiz-adv-x="625"
                d="M212.008 276.827l0 -276.827 -117.349 0 0 756.337 228.68 0c85.4966,0 151.16,-19.8336 196.666,-59.3392 45.6677,-39.5056 68.3219,-96.8327 68.3219,-172.161 0,-75.8313 -27.1635,-136.662 -81.4904,-182.832 -54.3269,-46.009 -121.499,-67.6751 -201.678,-65.1779l-93.1498 0zm0 381.348l0 -283.671 83.8259 0c113.001,0 169.502,48.4882 169.502,145.501 0,92.1618 -54.1652,138.171 -162.675,138.171l-90.6527 0z"
              />
              <glyph
                unicode="Q"
                horiz-adv-x="816"
                d="M516.171 3.83214l199.166 -189.828 -160.171 0 -158.171 173.826c-111.995,2.50563 -198.492,39.1742 -259.491,109.837 -61.009,70.663 -91.6661,161.171 -91.6661,271.503 0,119.491 32.1626,215.832 96.4984,289 64.3253,73.3266 156.16,109.827 275.662,109.827 109.332,0 195.334,-34.9946 258.006,-105.163 62.6619,-70.1682 93.9928,-162.34 93.9928,-276.672 0,-97.667 -20.4978,-180.332 -61.6617,-247.994 -41.3324,-67.6626 -105.331,-112.501 -192.165,-134.336zm-104.005 662.171c-76.8324,0 -136.831,-26.4986 -179.669,-79.5065 -42.9958,-53.1657 -64.4937,-122.997 -64.4937,-209.494 0,-87.3392 20.8346,-157.002 62.6619,-208.999 41.8377,-52.1656 100.004,-78.1694 174.668,-78.1694 76.1691,0 135.662,25.4985 178.332,76.6639 42.8379,51.1654 64.3358,120.67 64.3358,208.504 0,91.1608 -20.0029,162.498 -59.8299,213.832 -39.8374,51.5023 -98.5093,77.1693 -176.005,77.1693z"
              />
              <glyph
                unicode="V"
                horiz-adv-x="688"
                d="M680.163 756.333l-273 -756.333 -130.834 0 -268.5 756.333 128.342 0 191.495 -573.663c7.16827,-21.1679 12.1672,-44.6669 15.1719,-70.3352l2.33103 0c2.33103,21.5048 8.00366,45.1655 17.1661,71.3323l193.826 572.666 124.003 0z"
              />
              <glyph
                unicode="a"
                horiz-adv-x="560"
                d="M94.164 503.835c55.1665,32.6613 118.67,48.8303 191.006,48.8303 136.335,0 204.659,-70.335 204.659,-211.328l0 -341.337 -111.333 0 0 81.9969 -2.49608 0c-36.8349,-63.16 -91.4961,-94.6592 -164.004,-94.6592 -53.1656,0 -94.831,14.3297 -125.33,42.9993 -30.4987,28.6595 -45.6672,67.1618 -45.6672,115.659 0,101.501 60.3305,160.841 181.173,177.667l156.657 21.5047c0,80.5012 -35.1574,120.671 -105.826,120.671 -64.8376,0 -124.339,-21.0095 -178.839,-63.0084l0 101.006zm165.671 -252.832c-39.1693,-5.17407 -66.6667,-15.1685 -82.8356,-29.8317 -16.169,-14.6733 -24.1625,-36.168 -24.1625,-64.5041 0,-23.6673 8.6605,-43.1711 25.8299,-58.3295 17.3311,-15.0068 39.5028,-22.6669 66.4949,-22.6669 38.3407,0 70.335,13.6628 95.6697,40.8266 25.3347,27.174 37.9971,61.3309 37.9971,102.673l0 47.9915 -118.993 -16.1589z"
              />
              <glyph
                unicode="c"
                horiz-adv-x="505"
                d="M463.827 22.9955c-39.6673,-23.8219 -90.6527,-35.661 -152.83,-35.661 -78.6699,0 -142.5,24.828 -191.33,74.6636 -48.8296,49.8356 -73.3342,114.996 -73.3342,195.336 0,90.1676 26.3371,161.993 78.6699,215.332 52.3328,53.3388 122.164,79.9993 209.493,79.9993 53.6802,0 97.1741,-8.49757 130.338,-25.834l0 -107.324c-38.3379,27.6665 -79.8376,41.4997 -124.499,41.4997 -52.6741,0 -95.5033,-17.6778 -128.164,-52.9975 -32.6608,-35.3377 -49.0092,-82.8378 -49.0092,-142.339 0,-58.3332 15.504,-104.001 46.512,-137.003 31.1697,-33.0022 72.8311,-49.4943 125.326,-49.4943 45.506,0 88.4968,14.6596 128.829,43.8352l0 -100.013z"
              />
              <glyph
                unicode="d"
                horiz-adv-x="647"
                d="M566.005 0l-114.335 0 0 85.5035 -2.50619 0c-38.9975,-65.5045 -98.4993,-98.1658 -178.667,-98.1658 -68.3341,0 -122.834,24.3242 -163.327,72.9928 -40.5033,48.6686 -60.8357,114.335 -60.8357,196.999 0,89.5053 22.8286,161.174 68.4958,214.835 45.5055,53.6709 104.836,80.5012 178.172,80.5012 73.1646,0 125.33,-27.6691 156.162,-82.9973l2.50619 0 0 329.665 114.335 0 0 -799.333zm-113.334 316.336c0,41.1601 -13.3394,75.499 -39.8363,103.168 -26.4969,27.659 -59.33,41.4936 -98.3376,41.4936 -46.3342,0 -82.9973,-17.8263 -110.333,-53.3273 -27.3256,-35.501 -40.9984,-84.0079 -40.9984,-145.5 0,-56.6722 12.8341,-101.339 38.5023,-134 25.6682,-32.6714 60.6639,-49.0021 104.997,-49.0021 42.9993,0 78.0052,16.169 105.169,48.4968 27.1639,32.3379 40.8367,73.3364 40.8367,122.834l0 65.838z"
              />
              <glyph
                unicode="e"
                horiz-adv-x="571"
                d="M533.172 239.331l-371.007 0c4.83048,-109.161 60.3305,-163.66 166.5,-163.66 59.8353,0 114.335,17.9981 163.499,53.6608l0 -94.6693c-48.1633,-31.4992 -111.495,-47.3245 -189.834,-47.3245 -80.1576,0 -142.833,24.3242 -187.995,73.1646 -45.3337,48.8303 -68.0006,117.164 -68.0006,205.164 0,83.3308 24.3343,151.998 73.0029,206.003 48.6585,53.9942 109.666,80.9964 182.992,80.9964 72.8412,0 129.503,-23.0004 170.007,-69.0011 40.6649,-46.1624 60.8357,-109.494 60.8357,-190.167l0 -54.166zm-111.333 83.5026c-0.333485,46.1624 -10.8332,81.6634 -31.671,106.331 -20.8378,24.8396 -50.4977,37.1684 -88.8384,37.1684 -34.8239,0 -65.1609,-13.1676 -90.8292,-39.331 -25.8299,-26.1735 -41.6654,-60.9974 -47.8298,-104.169l259.168 0z"
              />
              <glyph
                unicode="f"
                horiz-adv-x="364"
                d="M378.498 705.504c-19.3292,9.15924 -38.9952,13.6652 -59.1666,13.6652 -57.998,0 -86.8338,-35.0052 -86.8338,-104.837l0 -74.3373 124.997 0 0 -90.8239 -124.997 0 0 -449.171 -114.333 0 0 449.171 -91.8346 0 0 90.8239 91.8346 0 0 80.1697c0,58.8297 17.6658,105.5 53.1657,139.831 35.5,34.3419 81.0014,51.5023 136.336,51.5023 28.6674,0 52.1656,-3.16889 70.8315,-9.66458l0 -96.33z"
              />
              <glyph
                unicode="g"
                horiz-adv-x="647"
                d="M566.334 49.8237c0,-202.487 -101.175,-303.662 -303.662,-303.662 -68.6643,0 -127.007,11.6725 -174.834,34.998l0 105.503c59.3224,-33.1571 116.823,-49.6671 172.503,-49.6671 127.497,0 191.324,65.1782 191.324,195.672l0 53.8386 -1.99765 0c-39.6592,-66.177 -99.3341,-99.1774 -179.162,-99.1774 -67.6655,0 -122.013,24.1676 -162.848,72.5029 -40.8147,48.3353 -61.32,112.828 -61.32,193.674 0,90.991 22.5029,163.67 67.1563,217.998 44.8296,54.1716 104.505,81.1594 179.005,81.1594 70.662,0 122.993,-27.83 157.168,-83.49l1.99765 0 0 70.8186 114.669 0 0 -490.168zm-113.67 266.51c0,40.1684 -13.161,74.1676 -39.5025,102.331 -26.4982,28.1629 -58.9894,42.3423 -97.6694,42.3423 -47.3169,0 -84.4888,-17.8418 -111.653,-53.5057 -27.1641,-35.6639 -40.6776,-84.998 -40.6776,-148.159 0,-54.6808 13.0043,-98.5116 39.0129,-131.179 25.989,-32.6675 60.3212,-49.0012 102.996,-49.0012 43.3216,0 78.6722,16.0008 106.169,48.3353 27.4971,32.1778 41.3239,72.8359 41.3239,121.994l0 66.8429z"
              />
              <glyph
                unicode="h"
                horiz-adv-x="624"
                d="M554.172 0l-114.669 0 0 309.499c0,100.999 -37.3286,151.508 -111.829,151.508 -37.8378,0 -69.1735,-14.5123 -94.34,-43.4978 -25.0098,-29.0051 -37.5049,-66.0008 -37.5049,-110.85l0 -306.659 -114.826 0 0 799.334 114.826 0 0 -345.672 2.50685 0c40.991,66.0008 98.6682,99.0012 173.169,99.0012 121.837,0 182.667,-73.5018 182.667,-220.662l0 -332.002z"
              />
              <glyph
                unicode="i"
                horiz-adv-x="275"
                d="M68.8249 725.671c0,19.1689 6.68307,34.9963 19.8336,47.5001 13.1685,12.5038 29.8403,18.8276 50.0153,18.8276 19.8336,0 36.6671,-6.32377 50.3207,-18.8276 13.6716,-12.5038 20.4984,-28.3312 20.4984,-47.5001 0,-19.3306 -6.8268,-35.176 -20.4984,-47.6618 -13.6536,-12.5038 -30.487,-18.8456 -50.3207,-18.8456 -19.8336,0 -36.5054,6.34174 -49.8356,19.0072 -13.3302,12.8272 -20.0133,28.4929 -20.0133,47.5001zm11.1744 -725.671l0 540 114.834 0 0 -540 -114.834 0z"
              />
              <glyph
                unicode="l"
                horiz-adv-x="275"
                d="M80.0013 0l0 799.328 114.827 0 0 -799.328 -114.827 0z"
              />
              <glyph
                unicode="m"
                horiz-adv-x="950"
                d="M879.83 0l-114.672 0 0 309.505c0,53.5005 -8.65925,92.0001 -25.8161,115.822 -17.3365,23.6782 -44.8413,35.679 -82.6761,35.679 -32.4991,0 -60.507,-15.001 -83.8259,-45.003 -23.4986,-30.002 -35.176,-66.5074 -35.176,-109.84l0 -306.164 -114.331 0 0 317.338c0,95.8267 -36.6671,143.668 -109.84,143.668 -33.8286,0 -61.9981,-14.498 -84.1672,-43.5118 -22.3308,-28.9959 -33.4872,-66.4894 -33.4872,-112.337l0 -305.158 -114.834 0 0 540 114.834 0 0 -84.9936 2.49717 0c38.6612,65.16 95.3236,97.6591 169.826,97.6591 75.8313,0 127.176,-35.8407 153.836,-107.827 40.332,71.9868 100.498,107.827 180.677,107.827 118.157,0 177.155,-72.9928 177.155,-219.158l0 -333.507z"
              />
              <glyph
                unicode="n"
                horiz-adv-x="625"
                d="M555.162 0l-114.169 0 0 305.158c0,103.839 -37.8348,155.849 -113.325,155.849 -38.1762,0 -69.6693,-14.498 -94.4972,-43.3322 -24.8459,-28.6725 -37.3318,-65.3396 -37.3318,-109.516l0 -308.158 -114.834 0 0 540 114.834 0 0 -88.3352 2.49717 0c39.9907,67.3338 98.5035,101.001 175.664,101.001 120.834,0 181.162,-74.1606 181.162,-222.661l0 -330.004z"
              />
              <glyph
                unicode="o"
                horiz-adv-x="642"
                d="M46.3324 265.167c0,88.8382 25.331,158.993 75.993,210.499 50.68,51.3267 119.505,76.9991 206.834,76.9991 82.3348,0 147.333,-24.828 195.175,-74.6636 48.0032,-49.8356 71.8251,-117.834 71.8251,-203.995 0,-87.0057 -25.1514,-156.513 -75.6517,-208.505 -50.5003,-52.1711 -117.834,-78.1668 -202.181,-78.1668 -82.6581,0 -148.662,25.1693 -197.995,75.6697 -49.3326,50.5003 -73.9989,117.834 -73.9989,202.163zm116.828 2.33548c0,-59.1776 14.3363,-105.33 43.1705,-138.674 28.8342,-33.1639 67.9985,-49.656 117.331,-49.656 103.839,0 155.831,63.8306 155.831,191.833 0,61.8364 -13.3302,108.995 -39.9907,141.333 -26.6604,32.3195 -65.3396,48.6679 -115.84,48.6679 -50.3207,0 -89.8263,-17.3365 -118.157,-51.8477 -28.1695,-34.4933 -42.3441,-81.6521 -42.3441,-141.656z"
              />
              <glyph
                unicode="p"
                horiz-adv-x="647"
                d="M195.828 72.8359l0 -321.328 -114.826 0 0 788.484 114.826 0 0 -89.8159 2.50685 0c41.6569,68.3314 102.174,102.487 181.492,102.487 68.6643,0 122.836,-23.9914 162.182,-71.9937 39.3263,-48.0024 58.9894,-113.004 58.9894,-195.006 0,-90.1684 -22.17,-162.495 -66.8233,-216.823 -44.6729,-54.3478 -104.348,-81.5119 -179.181,-81.5119 -68.3314,0 -120.662,28.4959 -156.659,85.5072l-2.50685 0zm-0.998825 154.661c0,-41.9898 13.0043,-77.1641 39.0129,-105.66 26.1653,-28.4959 58.9894,-42.6753 98.6486,-42.6753 47.8457,0 85.0176,18.3314 111.34,54.8374 26.3416,36.6627 39.5025,87.3286 39.5025,152.174 0,55.66 -12.1622,98.6682 -36.5061,129.162 -24.5006,30.3369 -58.6565,45.6718 -102.664,45.6718 -43.8308,0 -79.8277,-16.0008 -107.658,-47.8457 -27.83,-32.0016 -41.6765,-72.3267 -41.6765,-121.152l0 -64.5123z"
              />
              <glyph
                unicode="r"
                horiz-adv-x="394"
                d="M387.672 430.663c-16.8334,10.9947 -39.3439,16.6718 -67.3338,16.6718 -37.1701,0 -67.1721,-16.3304 -90.1676,-48.8296 -22.8338,-32.6788 -34.3316,-75.6697 -34.3316,-129.512l0 -268.994 -114.834 0 0 540 114.834 0 0 -107.324 2.49717 0c26.6604,77.8255 73.8372,116.666 141.494,116.666 20.175,0 36.1641,-2.33548 47.8415,-6.84476l0 -111.834z"
              />
              <glyph
                unicode="s"
                horiz-adv-x="472"
                d="M55.6617 125.501c50.5078,-34.167 102.835,-51.3365 157.172,-51.3365 71.6689,0 107.503,22.1717 107.503,66.505 0,18.4933 -7.50846,33.6618 -22.5052,45.6672 -14.9967,11.8337 -48.6686,27.4973 -100.995,46.9911 -55.0048,21.1712 -92.1732,43.8381 -111.333,67.6671 -19.332,24.0008 -28.8414,54.0043 -28.8414,90.1723 0,49.8307 20.8378,89.1617 62.5032,118.165 41.6654,29.0031 93.1636,43.3328 154.838,43.3328 47.1629,0 91.1626,-7.67015 132.333,-23.3338l0 -101.662c-41.1702,25.4964 -86.9991,38.1689 -137.84,38.1689 -28.8313,0 -52.3268,-6.00273 -70.3249,-18.0082 -17.8364,-12.1671 -26.8405,-27.8308 -26.8405,-47.4963 0,-19.1703 6.6697,-34.3287 19.8373,-45.3337 13.1676,-11.1667 43.4945,-25.6682 91.001,-43.9998 58.663,-21.5047 98.661,-44.3333 119.994,-68.4958 21.3329,-24.3343 31.8327,-54.4995 31.8327,-90.6675 0,-51.003 -20.8276,-91.3344 -62.4931,-120.499 -41.6654,-29.3366 -96.3367,-43.9998 -164.004,-43.9998 -57.9961,0 -108.494,9.99444 -151.837,30.1652l0 107.999z"
              />
              <glyph
                unicode="t"
                horiz-adv-x="385"
                d="M358.335 5.83871c-21.1631,-12.0008 -51.5064,-18.0012 -91.1737,-18.0012 -102.653,0 -153.836,55.6564 -153.836,166.987l0 294.343 -91.3174 0 0 90.8323 91.3174 0 0 126.493 114.169 34.6729 0 -161.166 130.841 0 0 -90.8323 -130.841 0 0 -274.329c0,-33.5052 6.0004,-57.8302 17.8395,-72.8311 11.8391,-15.001 31.008,-22.3488 57.3271,-22.3488 20.4984,0 39.1643,5.83871 55.6743,17.5161l0 -91.3354z"
              />
              <glyph
                unicode="u"
                horiz-adv-x="625"
                d="M544.996 0l-114.333 0 0 84.4967 -2.4951 0c-37.3318,-64.8306 -92.6663,-97.1617 -166.003,-97.1617 -127.166,0 -190.828,76.327 -190.828,229.002l0 323.658 114.164 0 0 -309.498c0,-100.994 38.8373,-151.328 116.333,-151.328 37.6687,0 68.6628,13.8336 92.6663,41.5009 24.1615,27.6672 36.1632,63.8305 36.1632,108.827l0 310.498 114.333 0 0 -539.995z"
              />
              <glyph
                unicode="ã"
                horiz-adv-x="560"
                d="M94.1637 503.839c55.1704,32.6675 118.664,48.8249 191.011,48.8249 136.33,0 204.661,-70.329 204.661,-211.34l0 -341.324 -111.34 0 0 82.0016 -2.48727 0c-36.839,-63.161 -91.5002,-94.6729 -164.003,-94.6729 -53.1727,0 -94.8296,14.3361 -125.343,43.0082 -30.4935,28.6722 -45.6718,67.1563 -45.6718,115.668 0,101.488 60.3408,160.83 181.179,177.654l156.659 21.5041c0,80.5131 -35.1547,120.662 -105.836,120.662 -64.8257,0 -124.324,-20.9949 -178.829,-62.9847l0 100.999zm165.668 -252.84c-39.1696,-5.17039 -66.6667,-15.1586 -82.8241,-29.8277 -16.177,-14.669 -24.1676,-36.1731 -24.1676,-64.5123 0,-23.6584 8.65648,-43.1649 25.8324,-58.3235 17.3325,-15.002 39.5025,-22.6596 66.4904,-22.6596 38.347,0 70.329,13.6506 95.6718,40.8147 25.3231,27.1837 37.9945,61.3396 37.9945,102.683l0 48.0024 -118.997 -16.177zm193.831 525.343c0,-41.3435 -8.83275,-74.3439 -26.8312,-98.8445 -17.8222,-24.6573 -41.9898,-36.839 -72.3267,-36.839 -26.6745,0 -55.3467,8.67607 -86.3298,25.8324 -27.6733,15.3349 -48.8445,23.0121 -63.5135,23.0121 -30.0039,0 -44.8296,-19.5065 -44.8296,-58.6761l-58.1669 0c0,42.0094 8.83275,75.0098 26.3416,98.8445 17.6655,23.9914 42.499,35.9969 74.6573,35.9969 25.1665,0 52.5069,-9.00901 82.1778,-26.8312 26.6549,-15.3349 48.6682,-23.0121 65.8245,-23.0121 28.6722,0 43.0082,20.1723 43.0082,60.517l59.9882 0z"
              />
              <glyph
                unicode="ç"
                horiz-adv-x="505"
                d="M463.839 22.9992c-39.0032,-23.5026 -86.5118,-35.3334 -142.499,-35.6646l0 -38.1685c65.6589,-1.82827 98.6606,-29.3319 98.6606,-82.4976 0,-66.997 -48.8335,-100.502 -146.5,-100.502 -15.3284,0 -30.3388,1.16586 -45.0047,3.33859l0 56.1598c13.3411,-2.33171 25.8343,-3.32534 37.6784,-3.32534 47.1642,0 70.8258,14.8249 70.8258,44.8325 0,25.6621 -21.5021,38.6587 -64.4931,38.6587 -3.6698,0 -10.6782,-0.662418 -21.0119,-2.0005l0 89.3337c-62.4925,11.3406 -112.333,41.5071 -149.495,90.1684 -37.0027,48.6613 -55.6696,106.663 -55.6696,174.004 0,89.8239 26.51,161.498 79.3312,214.995 53.0067,53.6691 122.508,80.3381 208.834,80.3381 53.6691,0 97.1768,-8.50545 130.337,-25.8343l0 -107.338c-38.3275,27.6758 -79.8347,41.5071 -124.495,41.5071 -53.3379,0 -96.3421,-18.1635 -128.668,-54.3316 -32.3393,-36.0091 -48.5023,-83.1732 -48.5023,-141.002 0,-57.6701 15.5006,-103.165 46.5018,-136.498 31.1602,-33.3329 72.8263,-49.9993 125.33,-49.9993 45.5081,0 88.4991,14.6659 128.84,43.8256l0 -99.9987z"
              />
              <glyph
                unicode="ó"
                horiz-adv-x="642"
                d="M46.3376 265.159c0,88.8367 25.3231,159.009 75.989,210.517 50.6659,51.3318 119.506,76.9879 206.835,76.9879 82.3345,0 147.336,-24.8335 195.163,-74.6573 48.0024,-49.8433 71.8371,-117.842 71.8371,-204.015 0,-86.9957 -25.1665,-156.483 -75.6561,-208.5 -50.5092,-52.1543 -117.842,-78.1629 -202.174,-78.1629 -82.6675,0 -148.668,25.1665 -198.002,75.6757 -49.3341,50.4896 -73.9914,117.822 -73.9914,202.154zm116.823 2.35018c0,-59.1853 14.3361,-105.347 43.1649,-138.68 28.8484,-33.1571 67.9984,-49.6671 117.333,-49.6671 103.839,0 155.836,63.8465 155.836,191.833 0,61.8292 -13.3373,109.009 -39.9922,141.344 -26.6745,32.3345 -65.3349,48.6682 -115.844,48.6682 -50.3329,0 -89.8159,-17.3325 -118.155,-51.841 -28.1629,-34.5084 -42.3423,-81.6686 -42.3423,-141.657zm296.338 528.32l-147.336 -170.329 -85.9969 0 124.501 170.329 108.833 0z"
              />
              <glyph
                unicode="õ"
                horiz-adv-x="642"
                d="M46.3295 265.166c0,88.8303 25.3309,159.007 76.0059,210.503 50.6618,51.3242 119.5,76.9995 206.834,76.9995 82.3254,0 147.335,-24.8407 195.162,-74.6678 47.9988,-49.8404 71.8326,-117.831 71.8326,-203.998 0,-87.002 -25.1586,-156.503 -75.6614,-208.503 -50.5028,-52.1721 -117.831,-78.1654 -202.17,-78.1654 -82.6698,0 -148.66,25.1719 -197.997,75.6614 -49.3369,50.5028 -74.0054,117.831 -74.0054,202.17zm116.837 2.33171c0,-59.1672 14.3347,-105.325 43.1632,-138.671 28.8417,-33.1607 68.0039,-49.6549 117.341,-49.6549 103.827,0 155.827,63.8306 155.827,191.823 0,61.8434 -13.3279,109.008 -39.9968,141.334 -26.669,32.3393 -65.3409,48.6745 -115.83,48.6745 -50.3438,0 -89.8372,-17.3421 -118.175,-51.8409 -28.166,-34.4987 -42.3285,-81.6629 -42.3285,-141.665zm336.495 508.83c0,-41.3217 -8.82341,-74.3233 -26.8279,-98.8328 -17.8323,-24.6552 -41.9973,-36.8305 -72.3361,-36.8305 -26.669,0 -55.3252,8.66443 -86.3264,25.8343 -27.6758,15.3284 -48.8335,22.9992 -63.4994,22.9992 -30.0076,0 -44.8325,-19.5016 -44.8325,-58.6638l-58.1736 0c0,41.9973 8.83666,74.999 26.3378,98.8328 17.6601,24.006 42.5008,35.9958 74.6678,35.9958 25.1586,0 52.49,-8.99564 82.1664,-26.8279 26.6557,-15.3284 48.6613,-22.9992 65.8311,-22.9992 28.6695,0 42.991,20.164 42.991,60.492l60.0019 0z"
              />
            </font>

            <linearGradient
              id="id0"
              gradientUnits="userSpaceOnUse"
              x1="1190.87"
              y1="2460.32"
              x2="798.17"
              y2="1175.12"
            >
              <stop offset="0" style="stop-opacity: 1; stop-color: #185139" />
              <stop offset="1" style="stop-opacity: 1; stop-color: #fefefe" />
            </linearGradient>
          </defs>
          <g id="Camada_x0020_1_1">
            <metadata id="CorelCorpID_0Corel-Layer" />
            <g id="_2718737475040">
              <path
                class="fil0"
                d="M3071.36 967.21c51.45,-20.58 102.89,-41.16 154.34,-41.16 205.8,0 216.08,144.05 246.95,329.27l51.45 10.29 0 -102.89c-10.29,-41.16 -10.29,-30.87 10.29,0 20.58,30.87 30.87,61.74 30.87,113.19l-20.58 20.58 -72.03 -10.29 -113.19 -30.87 -123.47 -72.03c0,0 -41.16,-20.58 -92.61,-82.31 -20.58,-20.58 -41.16,-41.16 -61.74,-61.74 -30.87,-51.45 -10.29,-72.03 -10.29,-72.03l0 0 -0.01 0z"
              />
              <path
                class="fil0"
                d="M3791.63 473.31c0,-92.61 -72.03,-174.92 -164.63,-174.92 -92.61,0 -164.63,82.31 -164.63,174.92 0,92.61 72.03,164.63 164.63,164.63 92.61,0 164.63,-72.03 164.63,-164.63z"
              />
              <path
                class="fil0"
                d="M3318.31 854.03c10.29,-20.58 61.74,-72.03 113.19,-123.47 20.58,-20.58 51.45,-41.16 61.74,-61.74 10.29,-10.29 10.29,-10.29 0,-30.87 0,-10.29 -133.77,-72.03 -61.74,-257.24 30.87,-61.74 72.03,-113.19 123.47,-123.47 102.89,-20.58 154.34,-10.29 195.5,41.16 20.58,10.29 20.58,20.58 30.87,30.87l298.39 0c0,0 -51.45,-154.34 -72.03,-329.27 -144.05,0 -257.24,0 -298.39,0 -20.58,10.29 -20.58,10.29 -41.16,10.29 -10.29,10.29 -10.29,10.29 0,10.29 51.45,10.29 61.74,10.29 113.19,20.58 51.45,10.29 92.61,20.58 123.47,41.16 30.87,10.29 61.74,41.16 82.31,72.03l0 20.58 -30.87 -10.29c-329.27,-246.95 -771.71,-102.89 -751.13,298.39 0,92.61 51.45,185.21 82.31,288.11l10.29 20.58 0 41.16 0 20.58 10.29 20.58 10.29 0 0 0 -0.01 -0.01z"
              />
              <path
                class="fil0"
                d="M3801.91 360.12c10.29,41.16 10.29,51.45 10.29,92.61l0 30.87 257.24 0c72.03,-41.16 41.16,-72.03 10.29,-123.47 -102.89,0 -185.21,0 -277.82,0l0 0 -0.01 -0.01z"
              />
              <path
                class="fil0"
                d="M3812.21 524.76l257.24 0c20.58,30.87 30.87,51.45 51.45,82.31 0,0 0,20.58 0,20.58 10.29,41.16 -20.58,30.87 -51.45,30.87 0,20.58 0,61.74 -10.29,82.31 0,30.87 0,61.74 -10.29,82.31 -20.58,41.16 -30.87,30.87 -82.31,30.87l-133.77 0c-102.89,61.74 -164.63,185.21 -216.08,288.11l-10.29 20.58 -20.58 -20.58c-51.45,-102.89 -133.77,-216.08 -246.95,-257.24l195.5 -195.5c10.29,-10.29 30.87,-10.29 51.45,-10.29 113.19,10.29 185.21,-30.87 226.37,-144.05l0 -10.29 0 0 0.01 0z"
              />
              <path
                class="fil1"
                d="M6888.78 1265.61l0 -102.89 -20.58 -627.66 -10.29 -473.32c72.03,0 144.05,0 205.8,0 41.16,0 102.89,0 185.21,0 0,51.45 -10.29,92.61 -10.29,123.47 0,30.87 0,92.61 0,185.21l0 421.88c0,41.16 -10.29,92.61 -10.29,164.63l72.03 -102.89 524.77 -668.82c10.29,-10.29 20.58,-30.87 30.87,-41.16 10.29,-20.58 30.87,-41.16 61.74,-82.31 82.31,0 154.34,0 205.8,0 30.87,0 92.61,0 185.21,0 -92.61,113.19 -205.8,246.95 -329.27,391l-493.9 607.08c-51.45,51.45 -102.89,123.47 -164.63,205.8 -102.89,0 -174.92,0 -216.08,0 -30.87,0 -113.19,0 -216.08,0l0 0 0 -0.01z"
              />
              <path
                class="fil1"
                d="M5602.59 1265.61c123.47,-288.11 236.66,-596.79 360.13,-946.64l-380.71 0c20.58,-102.89 41.16,-164.63 61.74,-257.24 164.63,0 391,0 617.38,0l216.08 0 216.08 0 113.19 0 -30.87 92.61c-20.58,41.16 -30.87,82.31 -51.45,113.19 -10.29,30.87 -10.29,51.45 -20.58,61.74 -102.89,0 -205.8,0 -308.69,0 -133.77,329.27 -205.8,576.21 -318.97,936.35 -102.89,0 -236.66,0 -267.53,0 -20.58,0 -92.61,0 -205.8,0l0 0 0.01 -0.01z"
              />
              <path
                class="fil1"
                d="M4347.26 1234.74c30.87,-123.47 41.16,-195.5 61.74,-277.82 51.45,20.58 72.03,30.87 113.19,41.16 51.45,10.29 144.05,10.29 174.92,10.29 51.45,0 102.89,-10.29 133.77,-20.58 61.39,-40.92 100.55,-105.25 41.16,-164.64l-123.47 -144.05c-30.87,-30.87 -51.45,-61.74 -61.74,-72.03 -221.4,-332.2 210.64,-586.51 524.77,-586.51 92.61,0 195.5,10.29 308.69,30.87 -10.29,41.16 -20.58,82.31 -30.87,113.19 0,30.87 -10.29,51.45 -20.58,144.05 -92.61,-10.29 -185.21,-20.58 -257.24,-20.58 -51.45,0 -92.61,10.29 -123.47,20.58 -52.35,26.18 -72.03,46.36 -72.03,102.9 0,53.54 128.35,169.49 174.93,216.08 51.59,54.06 125.32,160.58 82.32,288.12 -10.29,51.45 -41.16,102.89 -82.31,154.34 -41.16,41.16 -92.61,82.31 -144.05,113.19 -51.45,30.87 -113.19,51.45 -174.92,72.03 -61.74,10.29 -123.47,20.58 -185.21,20.58 -92.61,0 -205.8,-20.58 -339.55,-41.16l0 0 -0.04 -0.03z"
              />
            </g>
            <path
              class="fil2"
              d="M583.52 1989.42l18421.94 0c320.94,0 583.52,262.58 583.52,583.52l-583.52 2028.35 -18421.94 0 -583.52 -2028.35 0 0c0,-320.94 262.58,-583.52 583.52,-583.52z"
            />
            <path
              class="fil3"
              d="M1089.79 4592.42l17501.79 0c320.94,0 672.23,275.1 583.52,583.52l-583.52 2028.35 -17501.79 0 -583.52 -2028.35c-88.72,-308.43 262.58,-583.52 583.52,-583.52z"
            />
            <path
              class="fil4"
              d="M1734.67 7197.24l16282.94 0c320.94,0 672.23,275.1 583.52,583.52l-583.52 2028.35 -16282.94 0 -583.52 -2028.35c-88.72,-308.43 262.58,-583.52 583.52,-583.52z"
            />
            <path
              class="fil5"
              d="M2336.2 9810.15l15182.11 0c320.94,0 672.23,275.1 583.52,583.52l-583.52 2028.35 -15182.11 0 -583.52 -2028.35c-88.72,-308.43 262.58,-583.52 583.52,-583.52z"
            />
            <path
              class="fil6"
              d="M2901.99 12426.51l14083.97 0c320.94,0 672.23,275.1 583.52,583.52l-583.52 2028.35 -14083.97 0 -583.52 -2028.35c-88.72,-308.43 262.58,-583.52 583.52,-583.52z"
            />
            <path
              class="fil7"
              d="M3481.62 15038.75l13013.39 0c320.94,0 672.23,275.1 583.52,583.52l-583.52 2028.35 -13013.39 0 -583.52 -2028.35c-88.72,-308.43 262.58,-583.52 583.52,-583.52z"
            />
            <path
              class="fil8"
              d="M4088.89 17649.79l11880.31 0c320.94,0 672.23,275.1 583.52,583.52l-583.52 2028.35 -11880.31 0 -583.52 -2028.35c-88.72,-308.43 262.58,-583.52 583.52,-583.52z"
            />
            <path
              class="fil9"
              d="M10041.43 3604.03l0 -895.19 61.74 0 0 895.19 -61.74 0zm30.87 308.69l-277.82 -586.51 41.16 0 0 0 30.87 0 0 0 30.87 41.16 0 0 41.16 0 0 0 30.87 0 0 0 30.87 0 0 0 41.16 0 0 0 0 0 30.87 0 0 0 30.87 0 0 0 41.16 0 0 0 30.87 0 0 0 30.87 0 0 0 41.16 0 0 0 30.87 -41.16 0 0 30.87 0 0 0 41.16 0 0 0 -277.82 586.51 -0.04 0z"
            />
            <g transform="matrix(1 0 0 1 -6646.13 -7731.29)">
              <text
                x="9866.54"
                y="11394.41"
                class="fil10 fnt0 mao"
                @click="clicked('oportunidade')"
              >
                Leads
              </text>
            </g>
            <g transform="matrix(1 0 0 1 4166.91 -7374.83)">
              <text
                x="9866.54"
                y="11394.41"
                class="fil11 fnt1 mao"
                @click="clicked('oportunidade')"
              >
                {{ oportunidade ? oportunidade : 0 }}
              </text>
            </g>
            <path
              class="fil9"
              d="M10051.36 3662.14l0 -895.19 61.74 0 0 895.19 -61.74 0zm30.87 318.98l-277.82 -586.51 41.16 0 0 0 30.87 0 0 0 30.87 30.87 0 0 41.16 0 0 0 30.87 0 0 0 30.87 0 0 0 41.16 0 0 0 0 0 30.87 0 0 0 30.87 0 0 0 41.16 0 0 0 30.87 0 0 0 30.87 0 0 0 41.16 0 0 0 30.87 -30.87 0 0 30.87 0 0 0 41.16 0 0 0 -277.82 586.51 -0.04 0z"
            />
            <g transform="matrix(1 0 0 1 1763.46 -8827.03)">
              <text x="9866.54" y="11394.41" class="fil10 fnt2">
                Contatos com interesse
              </text>
            </g>
            <g id="_2718737461984">
              <g transform="matrix(1 0 0 1 -7823.8 -5083.57)">
                <text
                  x="9866.54"
                  y="11394.41"
                  class="fil10 fnt3 mao"
                  @click="clicked('qualificacao')"
                >
                  Qualificados
                </text>
              </g>
            </g>
            <path
              class="fil9"
              d="M10047.79 6195.16l0 -895.19 61.74 0 0 895.19 -61.74 0zm30.87 308.69l-277.82 -586.51 41.16 0 0 0 30.87 0 0 0 30.87 30.87 0 0 41.16 0 0 0 30.87 0 0 0 30.87 0 0 0 41.16 0 0 0 0 0 30.87 0 0 0 30.87 0 0 0 41.16 0 0 0 30.87 0 0 0 30.87 0 0 0 41.16 0 0 0 30.87 -30.87 0 0 30.87 0 0 0 41.16 0 0 0 -277.82 586.51 -0.04 0z"
            />
            <g transform="matrix(1 0 0 1 3973.32 -4705.75)">
              <text
                x="9866.54"
                y="11394.41"
                class="fil11 fnt1 mao"
                @click="clicked('qualificacao')"
              >
                {{ qualificacao ? qualificacao : 0 }}
              </text>
            </g>
            <g transform="matrix(1 0 0 1 2033.71 -6118.41)">
              <text x="9866.54" y="11394.41" class="fil10 fnt2">Cliente</text>
              <text x="11727.4" y="11394.41" class="fil10 fnt2">
                s em Potencial
              </text>
            </g>
            <path
              class="fil9"
              d="M10047.79 8750.11l0 -895.19 61.74 0 0 895.19 -61.74 0zm30.87 308.69l-277.82 -586.51 41.16 0 0 0 30.87 0 0 0 30.87 30.87 0 0 41.16 0 0 0 30.87 0 0 0 30.87 0 0 0 41.16 0 0 0 0 0 30.87 0 0 0 30.87 0 0 0 41.16 0 0 0 30.87 0 0 0 30.87 0 0 0 41.16 0 0 0 30.87 -30.87 0 0 30.87 0 0 0 41.16 0 0 0 -277.82 586.51 -0.04 0z"
            />
            <g transform="matrix(1 0 0 1 3518.67 -2072.47)">
              <text
                x="9996.54"
                y="11394.41"
                class="fil11 fnt1 mao"
                @mouseover="
                  handleMouseOver(
                    $event,
                    'Quantidade de clientes com pelo <br/>  menos uma visita agendada'
                  )
                "
                @mouseout="handleMouseOut"
                @mousemove="updateMousePosition"
                @click="clicked('agendamentos')"
              >
                {{ agendamentos ? agendamentos : 0 }}
              </text>
            </g>
            <g transform="matrix(1 0 0 1 1070.26 -3535.71)">
              <text x="9866.54" y="11394.41" class="fil10 fnt4">
                Oportunidade
              </text>
              <text x="13548.93" y="11394.41" class="fil10 fnt4">
                de Negócio
              </text>
            </g>
            <g id="_2718737465392">
              <text x="10883.05" y="13029.21" class="fil10 fnt5">
                Oportunidade
              </text>
              <text x="15329.94" y="13029.21" class="fil10 fnt5">Negócio</text>
              <text x="14563.29" y="13029.21" class="fil10 fnt5">de</text>
            </g>
            <text
              x="13251.84"
              y="14424.97"
              class="fil11 fnt6 mao"
              @click="clicked('propostas')"
            >
              {{ propostas ? propostas : 0 }}
            </text>
            <path
              class="fil9"
              d="M10044.69 14163.49l0 -895.19 61.74 0 0 895.19 -61.74 0zm30.87 318.97l-277.82 -586.51 41.16 0 0 0 30.87 0 0 0 30.87 30.87 0 0 41.16 0 0 0 30.87 0 0 0 30.87 0 0 0 41.16 0 0 0 0 0 30.87 0 0 0 30.87 0 0 0 41.16 0 0 0 30.87 0 0 0 30.87 0 0 0 41.16 0 0 0 30.87 -30.87 0 0 30.87 0 0 0 41.16 0 0 0 -277.82 586.51 -0.04 0z"
            />
            <text
              x="3670.89"
              y="14047.12"
              class="fil10 fnt7 mao"
              @click="clicked('propostas')"
            >
              Propostas
            </text>
            <g id="_2643047245536">
              <line
                class="fil12 str0"
                x1="11481.34"
                y1="12192.72"
                x2="11481.34"
                y2="12192.72"
              />
            </g>
            <g id="_2643047247040">
              <line
                class="fil12 str0"
                x1="11481.34"
                y1="12192.72"
                x2="11481.34"
                y2="12192.72"
              />
            </g>
            <text
              x="13486.05"
              y="11968.16"
              class="fil11 fnt8 mao"
              @mouseover="
                handleMouseOver(
                  $event,
                  'Quantidade de clientes com <br/> diagnóstico e/ou checklist <br/> completo ou parcial'
                )
              "
              @mouseout="handleMouseOut"
              @mousemove="updateMousePosition"
              @click="clicked('diagnosticos')"
            >
              {{ diagnosticos ? diagnosticos : 0 }}
            </text>
            <g transform="matrix(1 0 0 1 -6867.54 123.948)">
              <text
                x="9866.54"
                y="11394.41"
                class="fil10 fnt9 mao"
                @click="clicked('diagnosticos')"
              >
                Diagnósticos
              </text>
            </g>
            <g transform="matrix(1 0 0 1 1229.26 -976.328)">
              <text x="9866.54" y="11394.41" class="fil10 fnt10">
                Modelo de
              </text>
              <text x="12682.64" y="11394.41" class="fil10 fnt10">
                Diagnóstico
              </text>
            </g>
            <path
              class="fil9"
              d="M10054.68 11617.01l0 -895.19 61.74 0 0 895.19 -61.74 0zm30.87 308.68l-277.82 -576.21 41.16 0 0 0 30.87 0 0 0 30.87 30.87 0 0 41.16 0 0 0 30.87 0 0 0 30.87 0 0 0 41.16 0 0 0 0 0 30.87 0 0 0 30.87 0 0 0 41.16 0 0 0 30.87 0 0 0 30.87 0 0 0 41.16 0 0 0 30.87 -30.87 0 0 30.87 0 0 0 41.16 0 0 0 -277.82 576.21 -0.04 0z"
            />
            <g id="_2718737472160">
              <g transform="matrix(1 0 0 1 1756.04 8076.2)">
                <text
                  x="9866.54"
                  y="11394.41"
                  class="fil10 fnt11 mao"
                  @click="clicked('fechamento')"
                >
                  Vende
                </text>
              </g>
            </g>
            <g id="_2718737470144">
              <g transform="matrix(1 0 0 1 -5158.71 7958.78)">
                <text
                  x="9866.54"
                  y="11394.41"
                  class="fil10 fnt11 mao"
                  @click="clicked('fechamento')"
                >
                  Perde
                </text>
              </g>
            </g>
            <g transform="matrix(1 0 0 1 4406.5 8159.03)">
              <text
                x="9866.54"
                y="11394.41"
                class="fil11 fnt12 mao"
                @click="clicked('fechamento')"
              >
                {{ fechado ? fechado : 0 }}
              </text>
            </g>
            <g transform="matrix(1 0 0 1 -2776.69 8100.68)">
              <text
                x="9866.54"
                y="11394.41"
                class="fil11 fnt12 mao"
                @click="clicked('fechamento')"
              >
                {{ naoFechado ? naoFechado : 0 }}
              </text>
            </g>
            <path
              class="fil9"
              d="M10050.42 19255.11l0 -895.19 61.74 0 0 895.19 -61.74 0zm30.87 308.69l-277.82 -586.51 41.16 0 0 0 30.87 0 0 0 30.87 30.87 0 0 41.16 0 0 0 30.87 0 0 0 30.87 0 0 0 41.16 0 0 0 0 0 30.87 0 0 0 30.87 0 0 0 41.16 0 0 0 30.87 0 0 0 30.87 0 0 0 41.16 0 0 0 30.87 -30.87 0 0 30.87 0 0 0 41.16 0 0 0 -277.82 586.51 -0.04 0z"
            />
            <g id="_2718737470816">
              <g transform="matrix(1 0 0 1 1800.79 6857.89)">
                <text x="9866.54" y="11394.41" class="fil10 fnt10">
                  Ganha ou Perde
                </text>
              </g>
            </g>
            <g id="_2643047245536_0">
              <line
                class="fil12 str0"
                x1="10740.77"
                y1="17306.14"
                x2="10740.77"
                y2="17306.14"
              />
            </g>
            <g id="_2643047247040_1">
              <line
                class="fil12 str0"
                x1="10740.77"
                y1="17306.14"
                x2="10740.77"
                y2="17306.14"
              />
            </g>
            <g transform="matrix(1 0 0 1 2890.6 5566.3)">
              <text
                x="9866.54"
                y="11394.41"
                class="fil11 fnt13 mao"
                @click="clicked('negociacao')"
              >
                {{ negociacao ? negociacao : 0 }}
              </text>
            </g>
            <g id="_2683171311008">
              <g transform="matrix(1 0 0 1 -6094.78 5315.93)">
                <text
                  x="9866.54"
                  y="11394.41"
                  class="fil10 fnt14 mao"
                  @click="clicked('negociacao')"
                >
                  Negociações
                </text>
              </g>
            </g>
            <g transform="matrix(1 0 0 1 901.757 4236.8)">
              <text x="9866.54" y="11394.41" class="fil10 fnt10">Modelo</text>
              <text x="11908.13" y="11395.41" class="fil10 fnt10">
                de Negociação
              </text>
            </g>
            <path
              class="fil9"
              d="M10068.55 16730.44l0 -895.19 61.74 0 0 895.19 -61.74 0zm30.87 308.68l-277.82 -576.21 41.16 0 0 0 30.87 0 0 0 30.87 30.87 0 0 41.16 0 0 0 30.87 0 0 0 30.87 0 0 0 41.16 0 0 0 0 0 30.87 0 0 0 30.87 0 0 0 41.16 0 0 0 30.87 0 0 0 30.87 0 0 0 41.16 0 0 0 30.87 -30.87 0 0 30.87 0 0 0 41.16 0 0 0 -277.82 576.21 -0.04 0z"
            />
            <text x="10103.12" y="1090.54" class="fil13 fnt15">
              OPORTUNIDADES
            </text>
            <g id="_2683171318736">
              <g transform="matrix(1 0 0 1 -6303.03 -2453.56)">
                <text
                  x="9866.54"
                  y="11394.41"
                  class="fil10 fnt16 mao"
                  @click="clicked('agendamentos')"
                >
                  Visitas
                </text>
              </g>
            </g>
          </g>
        </svg>
        <div
          v-if="showTooltip"
          class="tooltip"
          :style="{ top: mousePosition.y + 'px', left: mousePosition.x + 'px' }"
          v-html="tooltipText"
        ></div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showTooltip: false,
      mousePosition: { x: 0, y: 0 },
      tooltipText: "",
    };
  },

  name: "FUNIL",
  props: [
    "oportunidade",
    "qualificacao",
    "agendamentos",
    "diagnosticos",
    "negociacao",
    "fechado",
    "naoFechado",
    "propostas",
  ],

  methods: {
    handleMouseOver(event, text) {
      this.showTooltip = true;
      this.updateMousePosition(event);
      this.tooltipText = text; // Atualize o texto do tooltip aqui
    },
    handleMouseOut() {
      this.showTooltip = false;
    },
    updateMousePosition(event) {
      this.mousePosition = { x: event.pageX + 10, y: event.pageY - 155 };
    },
    clicked(param) {
      // console.log(param);
      this.$emit("selected", param);
    },
    pesquisaFunil() {
      this.$store.dispatch("loadProspeccoes");
      this.$store.dispatch("setFiltroPesquisa");
      this.$store.dispatch("loadProspeccoesQualified");
      this.$store.dispatch("loadAgendamentosFunil");
      this.$store.dispatch("loadDiagnosticosFunil");
      this.$store.dispatch("loadNegociacoesFunil");
      this.$store.dispatch("loadNegociacoesFinalizadas");
      // this.$store.dispatch("loadNegociacoesFinalizadasFunil");
      this.showTabs = false;
    },
  },
  mounted() {
    // this.pesquisaFunil();
    // this.$store.dispatch("loadUnidadeUsuarios");
    // this.$store.dispatch("loadProspeccoes");
    // this.$store.dispatch("loadProspeccoesQualified");
    // this.$store.dispatch("loadSegmentacao");
    // this.$store.dispatch("loadEstados");
    // this.$store.dispatch("loadUsuarios");
    // this.$store.dispatch("loadCidades");
    // this.$store.dispatch("loadSegmentacao");
    // this.$store.dispatch("loadAgendamentos");
    // this.$store.dispatch("loadDiagnosticos");
    // this.$store.dispatch("loadNegociacoes");
    // this.$store.dispatch("loadNegociacoesFG");
    // this.$store.dispatch("loadNegociacoesFP");
    // this.atualizarFiltro();
    // const replacedSVG = this.meuSVG.replace("664", '123456');
    // this.meuSVG = replacedSVG;
  },
  created() {},
};
</script>

<style scoped>
.mao {
  cursor: pointer;
}
.vermelho {
  fill: brown;
}
.tela {
  height: 75vh;
}
/* 
@font-face {
  font-family: "Microsoft JhengHei";
  font-variant: normal;
  font-style: normal;
  font-weight: bold;
  src: url("#FontID1") format(svg);
}
@font-face {
  font-family: "MS PGothic";
  font-variant: normal;
  font-style: normal;
  font-weight: normal;
  src: url("#FontID0") format(svg);
}
.str0 {
  stroke: #373435;
  stroke-width: 35.06;
  stroke-miterlimit: 22.9256;
}
.fil13 {
  fill: none;
}
.fil7 {
  fill: #6b809b;
}
.fil2 {
  fill: #3c6c44;
}
.fil0 {
  fill: #185139;
}
.fil6 {
  fill: #387f87;
}
.fil4 {
  fill: #4e5a62;
}
.fil9 {
  fill: #51a13f;
}
.fil8 {
  fill: #a8518a;
}
.fil5 {
  fill: #f58634;
}
.fil11 {
  fill: #fefefe;
}
.fil12 {
  fill: #ffd412;
}
.fil1 {
  fill: #185139;
  fill-rule: nonzero;
}
.fil10 {
  fill: #fefefe;
  fill-rule: nonzero;
}
.fil3 {
  fill: url(#id1);
}
.fnt3 {
  font-weight: bold;
  font-size: 556.63px;
  font-family: "Microsoft JhengHei";
}
.fnt8 {
  font-weight: bold;
  font-size: 742.16px;
  font-family: "Microsoft JhengHei";
}
.fnt7 {
  font-weight: bold;
  font-size: 754.81px;
  font-family: "Microsoft JhengHei";
}
.fnt0 {
  font-weight: normal;
  font-size: 850.55px;
  font-family: "MS PGothic";
}
.fnt4 {
  font-weight: bold;
  font-size: 949.86px;
  font-family: "Microsoft JhengHei";
}
.fnt6 {
  font-weight: bold;
  font-size: 955.48px;
  font-family: "Microsoft JhengHei";
}
.fnt1 {
  font-weight: bold;
  font-size: 989.55px;
  font-family: "Microsoft JhengHei";
}
.fnt9 {
  font-weight: bold;
  font-size: 1051.4px;
  font-family: "Microsoft JhengHei";
}
.fnt5 {
  font-weight: bold;
  font-size: 1236.95px;
  font-family: "Microsoft JhengHei";
}
.fnt2 {
  font-weight: bold;
  font-size: 1484.34px;
  font-family: "Microsoft JhengHei";
} */
@font-face {
  font-family: "DejaVu Sans Mono";
  font-variant: normal;
  font-style: normal;
  font-weight: normal;
  src: url("#FontID3") format(svg);
}
@font-face {
  font-family: "Microsoft JhengHei";
  font-variant: normal;
  font-weight: normal;
  src: url("#FontID1") format(svg);
}
@font-face {
  font-family: "Microsoft JhengHei";
  font-variant: normal;
  font-style: normal;
  font-weight: bold;
  src: url("#FontID0") format(svg);
}
.str0 {
  stroke: #373435;
  stroke-width: 34.8;
  stroke-miterlimit: 22.9256;
}
.fil12 {
  fill: none;
}
.fil13 {
  fill: #175139;
}
.fil6 {
  fill: #3e4095;
}
.fil0 {
  fill: #185139;
}
.fil5 {
  fill: #387f87;
}
.fil3 {
  fill: #4e5a62;
}
.fil8 {
  fill: #51a13f;
}
.fil7 {
  fill: #a8518a;
}
.fil4 {
  fill: #f58634;
}
.fil10 {
  fill: #fefefe;
}
.fil11 {
  fill: #ffd412;
}
.fil1 {
  fill: #185139;
  fill-rule: nonzero;
}
.fil9 {
  fill: #fefefe;
  fill-rule: nonzero;
}
.fil2 {
  fill: url(#id0);
}
.fnt4 {
  font-weight: bold;
  font-size: 506.87px;
  font-family: "Microsoft JhengHei";
}
.fnt10 {
  font-weight: bold;
  font-size: 506.87px;
  font-family: "Microsoft JhengHei";
}
.fnt5 {
  font-weight: normal;
  font-size: 529.69px;
  font-family: "Microsoft JhengHei";
}
.fnt2 {
  font-weight: bold;
  font-size: 552.57px;
  font-family: "Microsoft JhengHei";
}
.fnt11 {
  font-weight: bold;
  font-size: 736.74px;
  font-family: "Microsoft JhengHei";
}
.fnt14 {
  font-weight: bold;
  font-size: 749.3px;
  font-family: "Microsoft JhengHei";
}
.fnt15 {
  font-weight: normal;
  font-size: 915.24px;
  font-family: "DejaVu Sans Mono";
}
.fnt3 {
  font-weight: bold;
  font-size: 942.92px;
  font-family: "Microsoft JhengHei";
}
.fnt9 {
  font-weight: bold;
  font-size: 945.54px;
  font-family: "Microsoft JhengHei";
}
.fnt7 {
  font-weight: bold;
  font-size: 948.5px;
  font-family: "Microsoft JhengHei";
}
.fnt0 {
  font-weight: bold;
  font-size: 982.32px;
  font-family: "Microsoft JhengHei";
}
.fnt16 {
  font-weight: bold;
  font-size: 982.32px;
  font-family: "Microsoft JhengHei";
}
.fnt12 {
  font-weight: bold;
  font-size: 1043.72px;
  font-family: "Microsoft JhengHei";
}
.fnt6 {
  font-weight: bold;
  font-size: 1227.89px;
  font-family: "Microsoft JhengHei";
}
.fnt13 {
  font-weight: bold;
  font-size: 1227.92px;
  font-family: "Microsoft JhengHei";
}
.fnt8 {
  font-weight: bold;
  font-size: 1468.45px;
  font-family: "Microsoft JhengHei";
}
.fnt1 {
  font-weight: bold;
  font-size: 1473.5px;
  font-family: "Microsoft JhengHei";
}

.tooltip {
  position: absolute;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.75);
  color: #fff;
  border-radius: 3px;
}
</style>