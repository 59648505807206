import axios from 'axios'
export default {
    state: {
        cep: [],
        dialogCep: false,
        loadingCep: false
    },
    mutations: {
        setCep(state, payload) {
            state.cep = payload
        },
        setLoadingCep(state, payload) {
            state.loadingCep = payload
        },
        setDialogCep(state, payload) {
            state.dialogCep = payload
        },
    },
    actions: {
        setCep(context, payload) {
            context.commit('setCep', payload)
        },
        setLoadingCep(context, payload) {
            context.commit('setLoadingCep', payload)
        },
        setDialogCep(context, payload) {
            context.commit('setDialogCep', payload)
        },
        loadAddressByCep(context, payload) {
            context.commit('setDialogCep', true);
            context.commit('setLoadingCep', true);
            axios(`/cep/?cep=${payload}`).then((resp) => {
                if (resp.status < 300) {
                    context.commit('setCep', [resp.data])
                    context.commit('setLoadingCep', false);
                } else {
                    context.commit('setLoadingCep', false);
                }
            });
        },
        // loadCepByAddress(context, payload) {
        //     context.commit('setDialogCep',true)
        //     context.commit('setLoadingCep',true)
        //     axios(`/cep?uf=${payload.uf}&logradouro=${payload.logradouro}&cidade=${payload.cidade}`).then((resp) => {
        //         // console.log(resp);
        //         if (resp.status < 300) {
        //             context.commit('setCep', resp.data)
        //         }
        //         context.commit('setLoadingCep',false)
        //     });
        // }

        loadCepByAddress(context, payload) {
            context.commit('setDialogCep', true);
            context.commit('setLoadingCep', true);
            
            if (payload.uf) { // Verifica se o UF está definido
              axios.get(`/cep`, {
                params: {
                  uf: payload.uf,
                  logradouro: payload.logradouro,
                  cidade: payload.cidade
                }
              }).then((resp) => {
                if (resp.status < 300) {
                  context.commit('setCep', resp.data);
                }
                context.commit('setLoadingCep', false);
              });
            } else {
              // Lógica para lidar com o UF não definido
              // Por exemplo, exibir uma mensagem de erro ou fazer outra ação necessária
              context.commit('setLoadingCep', false);
            }
          }
          
    },
    getters: {
        getCep(state) {
            return state.cep
        },
        getLoadingCep(state) {
            return state.loadingCep
        },
        getDialogCep(state) {
            return state.dialogCep
        },
    }
}