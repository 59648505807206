import axios from 'axios'
import store from '../index'
export default {
    state: {
        cidades: [],
        dialogCidade: false,
        estados: []
    },
    mutations: {
        setCidades(state, payload) {
            state.cidades = payload
        },
        setEstados(state, payload) {
            state.estados = payload
        },
        setDialogCidades(state, payload) {
            state.dialogCidade = payload
        },
    },
    actions: {
        setCidades({ commit }, payload) {
            commit('setCidades', payload)
        },
        setEstados({ commit }, payload) {
            commit('setEstados', payload)
        },
        setDialogCidades({ commit }, payload) {
            commit('setDialogCidades', payload)
        },
        async loadCidades({ commit }, payload) {
            if (store.state.index.user.user.user_id === undefined) {
                // O usuário não está autenticado, então retornamos e evitamos a solicitação
                return;
            }
            try {
                const resp = await axios(`/cidades/${payload ? '?uf=' + payload : ''}`)
                if (resp.status < 300) {
                    commit('setCidades', resp.data)
                }
            } catch (error) {
                console.error(error);
            }
        },
        async loadEstados({ commit }) {
            // console.log("authtoken", store.state.index.user.user.user_id === undefined);
            if (store.state.index.user.user.user_id === undefined) {
                // O usuário não está autenticado, então retornamos e evitamos a solicitação
                return;
            }
            try {
                const resp = await axios(`/estados/`)
                if (resp.status < 300) {
                    commit('setEstados', resp.data)
                }
            } catch (error) {
                console.error(error);
            }
        }
    },
    getters: {
        getCidades(state) {
            return state.cidades
        },
        getEstados(state) {
            return state.estados
        },
        getDialogCidades(state) {
            return state.dialogCidade
        },
        getCidadesSelect: (state) => {
            return state.cidades.map(element => ({
                value: element.id, text: element.municipio
            }));
        },
        getEstadosSelect: (state) => {
            return state.estados.map(element => ({
                value: element.id, text: `(${element.uf}) - ${element.estado}`, uf: element.uf
            }));
        },
        getCidadeById: (state) => (id) => {
            return state.cidades.find(g => g.id == id) || id
        },
        getCidadeNameById: (state) => (id) => {
            const obj = state.cidades.find(g => g.id == id)
            return obj ? obj.municipio : ""
        },
        getEstadoById: (state) => (id) => {
            return state.estados.find(g => g.id == id) || id
        },
        getEstadoNameById: (state) => (id) => {
            const obj = state.estados.find(g => g.id == id)
            return obj ? obj.estado : ""
        },
    }
}
