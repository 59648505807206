import Vue from "vue";
import store from '../store/index'
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: { requiresAuth: true }
  },
  {
    path: '/perfil',
    name: 'perfil',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/PerfilView.vue'),
  },
  {
    path: "/about",
    name: "about",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/request",
    name: "request",
    component: () =>
      import(/* webpackChunkName: "request" */ "../views/Request.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
  },
  {
    path: "/prospeccao",
    name: "prospeccao",
    component: () =>
      import(/* webpackChunkName: "prospeccao" */ "../views/Prospeccao.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/relatorioagenda",
    name: "relatorioagenda",
    component: () =>
      import(/* webpackChunkName: "prospeccao" */ "../views/RelatorioAgendamento.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/relatoriooportunidades",
    name: "relatoriooportunidades",
    component: () =>
      import(/* webpackChunkName: "prospeccao" */ "../views/RelatorioOportunidades.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/relatorioestrelas",
    name: "relatorioEstrelas",
    component: () =>
      import(/* webpackChunkName: "prospeccao" */ "../views/RelatorioEstrelas.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/relatorioqualificados",
    name: "relatorioqualificados",
    component: () =>
      import(/* webpackChunkName: "prospeccao" */ "../views/RelatorioQualificados.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/relatoriomaster",
    name: "relatoriomaster",
    component: () =>
      import(/* webpackChunkName: "prospeccao" */ "../views/RelatorioMaster.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/edtProspeccao/:id?",
    name: "edtProspeccao",
    props: true,
    component: () =>
      import(/* webpackChunkName: "prospeccao" */ "../views/Prospeccao.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/listarProspec",
    name: "listarProspec",
    component: () =>
      import(/* webpackChunkName: "listarProspec" */ "../views/ListarProspeccao.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/listarProspecQuali",
    name: "listarProspecQuali",
    component: () =>
      import(/* webpackChunkName: "listarProspecQuali" */ "../views/ListarProspeccaoQuali.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/listarProspecDiag",
    name: "listarProspecDiag",
    component: () =>
      import(/* webpackChunkName: "listarProspecDiag" */ "../views/ListarProspeccaoDiag.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/diagnostico/:id?",
    name: "diagnostico",
    props: true,
    component: () =>
      import(/* webpackChunkName: "Diagnostico" */ "../views/Diagnostico.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/maps",
    name: "maps",
    props: true,
    component: () =>
      import(/* webpackChunkName: "Diagnostico" */ "../views/Maps.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/listarNegociacoes/:id?",
    name: "listarNegociacoes",
    props: true,
    component: () =>
      import(/* webpackChunkName: "Diagnostico" */ "../views/ListarNegociacoesCli.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/lstNegociacoes",
    name: "lstNegociacoes",
    props: true,
    component: () =>
      import(/* webpackChunkName: "Diagnostico" */ "../views/ListarNegociacoes.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/lstPropostas",
    name: "lstPropostas",
    props: true,
    component: () =>
      import(/* webpackChunkName: "Diagnostico" */ "../views/ListarPropostas.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/negociacoesFinalizadas",
    name: "negociacoesFinalizadas",
    props: true,
    component: () =>
      import(/* webpackChunkName: "Diagnostico" */ "../views/ListarNegociacoesFinalizadas.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/negociacao/:id?",
    name: "negociacao",
    props: true,
    component: () =>
      import(/* webpackChunkName: "negociacao" */ "../views/Negociacao.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/agendamento",
    name: "agendamento",
    props: true,
    component: () =>
      import(/* webpackChunkName: "fullCalendar" */ "../views/Fullcalendar.vue"),
    meta: { requiresAuth: true }
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name == "NotFound") {
    return next({ path: store.getters.getDefaultRoute })
  }
  let logged = store.getters.getLogged
  if (to.matched.some(record => record.meta.requiresAuth)) {
    logged ? next() : next({ path: '/login' })
  } else {
    next()
  }

})

export default router;
