<template>
  <v-app>
    <AppBar></AppBar>
    <v-main>
      <v-container fluid class="px-0 py-0 bgL overflow-y-auto">
        <router-view fixed />
        <v-snackbar
          v-model="snck.snackbar"
          :color="snck.color"
          :timeout="snck.timeout"
        >
          {{ snck.text }}
        </v-snackbar>
      </v-container>
    </v-main>
    <v-footer v-if="$route.path != '/login'" color="dark" app>
      <span class="dark--text">&copy; STV Segurança 2023</span>
    </v-footer>
  </v-app>
</template>

<script>
import jwtDecode from "jwt-decode";
import { mapGetters } from "vuex";
import filtro from "./components/filtro";
import AppBar from "./components/BarraSuperior/AppBar.vue";

export default {
  name: "App",
  components: {
    filtro,
    AppBar,
  },

  data: () => ({
    snck: {},
    darkMode: false,
  }),
  computed: {
    ...mapGetters(["snackbar"]),
  },

  methods: {
    vai(para) {
      this.$router.push({ path: para });
    },

    navigateToHome() {
      this.$router.push("/").catch((err) => {
        if (err.name !== "NavigationDuplicated") {
          throw err;
        }
      });
    },

    logout() {
      this.$store.dispatch("logout");
      localStorage.clear();
      sessionStorage.clear();
      this.$router.push({ path: "/login" });
    },
    async getUserAvatar() {
      try {
        if (this.$store.getters.getUser.user == undefined) {
          return;
        }
        let idUser = await this.$store.state.index.user.user.user_id;
        if (idUser == undefined) {
          return;
        }
        let response = await this.$http.get("user/image/" + idUser + "/");
        this.$store.dispatch("setAvatar", response.data.avatar);
        this.avatar = response.data.avatar;
      } catch (error) {
        console.log(error);
      }
    },
  },
  watch: {
    snackbar() {
      this.snck = this.snackbar;
    },
    darkMode(value) {
      this.$vuetify.theme.dark = value;
    },
  },
  created() {
    // Descobre tema do navegador
    // this.$vuetify.theme.dark = window.matchMedia("(prefers-color-scheme: dark)");
    this.darkMode = this.$vuetify.theme.dark;
    const rota = window.location.href;
    if (rota.includes("email")) {
      const id = rota.split("=")[1];
      this.$store.dispatch("setClienteFiltro", id);
      this.$router.push({ path: "/login" });
    }
    // Verifica se o usuário está logado
    const token = localStorage.getItem("user-token");
    if (token) {
      this.$store.commit("setToken", token);
      const decoded = jwtDecode(token);
      this.$store.dispatch("setUser", { user: decoded });
      this.$store.dispatch("setAccess", { token: token });
      this.getUserAvatar();
      this.$router.push({ path: "/" });
    } else {
      this.$router.push({ path: "/login" });
    }
  },
};
</script>

<style scoped>
.mao {
  cursor: pointer;
}

.bgL {
  width: 100%;
  height: 100%;
  background: url("assets/fundo.jpg") no-repeat center center;
  background-size: cover;
}

</style>
<style>
.theme--light.v-card > .v-card__text, .theme--light.v-card > .v-card__subtitle {
    color: #000;
    font-weight: 500;
}
</style>
