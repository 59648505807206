import axios from 'axios'
import utils from '../../utils/querybuilder'
export default {
    state: {
        agendamentos: [],
        agendamento: false,
        dialogAgendar: false,
        tipoAgendamento: []
    },
    mutations: {
        setAgendamentos(state, payload) {
            state.agendamentos = payload
        },
        setAgendamento(state, payload) {
            state.agendamento = payload
        },
        setDialogAgendar(state, payload) {
            state.dialogAgendar = payload
        },
        setTipoAgendamento(state, payload) {
            state.tipoAgendamento = payload
        },
    },
    actions: {
        setAgendamentos(context, payload) {
            context.commit('setAgendamentos', payload)
        },
        setAgendamento(context, payload) {
            context.commit('setAgendamento', payload)
        },
        setDialogAgendar(context, payload) {
            context.commit('setDialogAgendar', payload)
        },
        setTipoAgendamento(context, payload) {
            context.commit('setTipoAgendamento', payload)
        },
        loadAgendamentosFunil(context) {
            let filtro = context.getters.getFiltroPesquisa;
            // filtro = { ...filtro, ...payload }
            let queryString = utils.querybuilder(filtro);
            // let queryString = utils.querybuilder(payload);
            // console.log("loadagendamentosfunil", queryString);
            axios(`/agendamento/${queryString}`).then((resp) => {
                if (resp.status < 300) {
                    context.commit('setAgendamentos', resp.data)
                }
            });
        },
        loadAgendamentos(context) {
            let filtro = context.getters.getFiltroPesquisa;
            // filtro = { ...filtro, ...payload }
            let queryString = utils.querybuilder(filtro);
            axios(`/agendamento/${queryString}`).then((resp) => {
                if (resp.status < 300) {

                    context.commit('setAgendamentos', resp.data)
                }
            });
        },
        loadTipoAgendamento(context) {
            axios(`/agendamentotipo/`).then((resp) => {
                if (resp.status < 300) {
                    context.commit('setTipoAgendamento', resp.data)
                }
            });
        },
        saveAgendamento(context, payload) {
            const metodo = payload.id ? "patch" : "post";
            const finalUrl = payload.id ? `${payload.id}` : "";
            if (metodo == "patch") {
                if (payload.cliente) {
                    payload.cliente_id = payload.cliente;
                    delete payload.cliente;
                }

                axios[metodo](`/agendamento/${finalUrl}/`, payload).then((resp) => {
                    if (resp.status < 300) {
                        context.dispatch("resetSnack");
                        context.dispatch("showSuccessSnack", "Agendamento salvo com Sucesso");
                        context.dispatch("loadAgendamentos");

                        if (payload.cliente_id) {

                            let data = payload.start.split("T")[0];
                            let hora = payload.start.split("T")[1];
                            let dataFormatada = data.split("-").reverse().join("/");

                            let iniObs = metodo == "patch" ? " Alterou o agendamento do cliente" : "Criou um agendamento para o cliente";
                            let obj = {
                                observacao: `${iniObs} para o dia ${dataFormatada} às ${hora}`,
                                cliente: payload.cliente_id,
                                metodo: "loadAgendamentos",
                                parametro: null
                            }
                            context.dispatch("saveHistorico", obj);

                            let etapaCliente = {
                                cliente: payload.cliente_id,
                                etapa_id: 3,
                            }
                            context.dispatch("saveEtapasClientes", etapaCliente);
                        }
                    }
                });
            } else {
                if (payload.cliente) {
                    payload.cliente_id = payload.cliente;
                    delete payload.cliente;
                }

                axios[metodo](`/agendamento/${finalUrl}`, payload).then((resp) => {
                    if (resp.status < 300) {
                        context.dispatch("resetSnack");
                        context.dispatch("showSuccessSnack", "Agendamento salvo com Sucesso");
                        context.dispatch("loadAgendamentos");

                        if (payload.cliente_id) {
                            let data = payload.start.split("T")[0];
                            let hora = payload.start.split("T")[1];
                            let dataFormatada = data.split("-").reverse().join("/");

                            let iniObs = metodo == "patch" ? " Alterou o agendamento do cliente" : "Criou um agendamento para o cliente";
                            let obj = {
                                observacao: `${iniObs} para o dia ${dataFormatada} às ${hora}`,
                                cliente: payload.cliente_id,
                                metodo: "loadAgendamentos",
                                parametro: null
                            }
                            context.dispatch("saveHistorico", obj);

                            let etapaCliente = {
                                cliente: payload.cliente_id,
                                etapa_id: 3,
                            }
                            context.dispatch("saveEtapasClientes", etapaCliente);
                        }
                    }
                });
            }
        },
        deletAgendamento(context, payload) {
            axios.delete(`/agendamento/${payload.id}`).then((resp) => {
                if (resp.status < 300) {
                    context.dispatch("resetSnack");
                    context.dispatch("showSuccessSnack", "Agendamento Deletado com Sucesso");
                    context.dispatch("loadAgendamentos");

                    if (payload.cliente) {
                        let data = payload.start.split("T")[0];
                        let hora = payload.start.split("T")[1];
                        let dataFormatada = data.split("-").reverse().join("/");
                        let obj = {
                            observacao: `Deletou o agendamento do cliente para o dia ${dataFormatada} às ${hora}`,
                            cliente: payload.cliente,
                            metodo: "loadAgendamentos",
                            parametro: null
                        }
                        context.dispatch("saveHistorico", obj);
                    }
                }
            });
        },
    },
    getters: {
        getAgendamentos(state) {
            return state.agendamentos
        },
        getAgendamento(state) {
            return state.agendamento
        },
        getDialogAgendar(state) {
            return state.dialogAgendar
        },
        getTipoAgendamento(state) {
            return state.tipoAgendamento
        },
        getTipoAgendamentoById: (state) => (id) => {
            if (!state.tipoAgendamento) {
                return id
            }
            let obj = state.tipoAgendamento.find(g =>
                g.id == id
            )
            return obj
        },
        getTipoAgendamentoNameById: (state) => (id) => {
            if (!state.tipoAgendamento) {
                return id
            }
            let obj = state.tipoAgendamento.find(g =>
                g.id == id
            )
            if (obj) {
                return obj.nome
            } else {
                return ""

            }
        },
        // getTipoAgendamentoSelect: (state) => {
        //     let gp = []
        //     state.tipoAgendamento.forEach(element => {
        //         console.log("element", element);
        //         gp.push({ value: element.id, text: `${element.nome}` })
        //     });
        //     return gp
        // },
        getTipoAgendamentoSelect: (state) => {
            let gp = []
            state.tipoAgendamento.forEach(element => {
                gp.push({
                    value: element.id,
                    text: `${element.nome}`,
                    backgroundColor: element.backgroundColor,
                    borderColor: element.borderColor,
                    textColor: element.textColor
                })
            });
            // console.log("getTipoAgendamentoSelect", gp);
            return gp
        },
        getAgendamentoById: (state) => (id) => {
            if (!state.agendamentos) {
                return id
            }
            let obj = state.agendamentos.find(g =>
                g.id == id
            )
            return obj
        },
    },
}