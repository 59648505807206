import axios from 'axios'
export default {
    state: {
        origem: [],
        atendente: [],
        comoChegou:[]
    },
    mutations: {
        setOrigem(state, payload) {
            state.origem = payload
        },
        setAtendente(state, payload) {
            state.atendente = payload
        },
        setComoChegou(state, payload) {
            state.comoChegou = payload
        },
    },
    actions: {
        setOrigem(context, payload) {
            context.commit('setOrigem', payload)
        },
        setAtendente(context, payload) {
            context.commit('setAtendente', payload)
        },
        setComoChegou(context, payload) {
            context.commit('setComoChegou', payload)
        },
        loadOrigem(context) {
            axios(`/origem/`).then((resp) => {
                if (resp.status < 300) {
                    context.commit('setOrigem', resp.data)
                }
            });
        },
        loadAtendente(context) {
            axios(`/usuario/`).then((resp) => {
                if (resp.status < 300) {
                    context.commit('setAtendente', resp.data)
                }
            });
        },
        loadComoChegou(context) {
            axios(`/comoChegou/`).then((resp) => {
                if (resp.status < 300) {
                    context.commit('setComoChegou', resp.data)
                }
            });
        }
    },
    getters: {
        getOrigem(state) {
            return state.origem
        },
        getAtendente(state) {
            return state.atendente
        },
        getComoChegou(state) {
            return state.comoChegou
        },
        getOrigemSelect: (state) =>  {
            let gp = []
            state.origem.forEach(element => {
                gp.push({value: element.id, text: element.nome})
            });
            return gp 
        },
        // getAtendenteSelect: (state) =>  {
        //     let gp = []
        //     state.atendente.forEach(element => {
        //         gp.push({value: element.id, text: element.nome})
        //     });
        //     return gp 
        // },
        getAtendenteSelect: (state) => {
            let gp = [];
            state.atendente.forEach(element => {
              gp.push({ value: element.id, text: element.username });
            });
            return gp;
          },
        getComoChegouSelect: (state) =>  {
            let gp = []
            state.comoChegou.forEach(element => {
                gp.push({value: element.id, text: `${element.nome}`})
            });
            return gp 
        },
        getOrigemById: (state) => (id) => {
            if (!state.origem) {
                return id
            }
            let obj = state.origem.find(g =>
                g.id == id
            )
            return obj
        },
        getAtendenteById: (state) => (id) => {
            if (!state.atendente) {
                return id
            }
            let obj = state.atendente.find(g =>
                g.id == id
            )
            return obj
        },
        getComoChegouById: (state) => (id) => {
            if (!state.comoChegou) {
                return id
            }
            let obj = state.comoChegou.find(g =>
                g.id == id
            )
            return obj
        },
        getOrigemNameById: (state) => (id) => {
            if (!state.origem || !id) {
                return null
            }
            let obj = state.origem.find(g =>
                g.id == id
            )


            
            return obj.nome
        },
    }
}