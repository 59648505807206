export default {
    state: {
        filtroPesquisa: {},
        clienteFiltro: null,
        clienteNome: {},
        textoFiltro: ''
    },
    mutations: {
        setFiltroPesquisa(state, payload) {
            state.filtroPesquisa = payload
        },
        setClienteFiltro(state, payload) {
            state.clienteFiltro = payload
        },
        setTextoFiltro(state, payload) {            
            state.textoFiltro = payload
        }
    },
    actions: {
        setFiltroPesquisa(context, payload) {
            context.commit('setFiltroPesquisa', payload)
        },
        setClienteFiltro(context, payload) {
            context.commit('setClienteFiltro', payload)
        },
        setClienteNome(context, payload) {
            context.commit('setClienteNome', payload)
        },
        setTextoFiltro(context, payload) {            
            context.commit('setTextoFiltro', payload)
        }
    },
    getters: {
        getFiltroPesquisa(state) {
            return state.filtroPesquisa
        },
        getClienteFiltro(state) {
            return state.clienteFiltro
        },
        getTextoFiltro(state) {
            return state.textoFiltro
        }
    }
}