import axios from 'axios'

export default {
  state: {
    etapasClientes: [],
    etapas: []
  },
  mutations: {
    setEtapasClientes(state, payload) {
      state.etapasClientes = payload
    },
    setEtapas(state, payload) {
      state.etapas = payload
    }
  },
  actions: {
    // setters
    setEtapasClientes(context, payload) {
      context.commit('setEtapasClientes', payload)
    },
    setEtapas(context, payload) {
      context.commit('setEtapas', payload)
    },
    // loaders
    loadEtapasClientes(context, payload) {
      let cliente = payload;
      const query = `?cliente=${cliente}`;
      axios(`/etapacliente/${cliente !== undefined? query : ''}`).then((resp) => {
        if (resp.status < 300) {
          context.commit('setEtapasClientes', resp.data);
        }
      });
    },
    loadEtapas(context) {
      axios(`/etapa/`).then((resp) => {
        if (resp.status < 300) {
          context.commit('setEtapas', resp.data);
        }
      });
    },
    // Save
    saveEtapasClientes(context, payload) {
      if(!payload.manual){
        payload.manual = false;
      } else {
        payload.manual = true;
      }
      axios.post('/etapacliente/', payload).then((resp) => {
        if (resp.status < 300) {
          context.dispatch('loadEtapasClientes', payload.cliente)
        }
      })
    },
  },
  getters: {
    getEtapasClientes: state => state.etapasClientes,
    getEtapas: state => state.etapas,
    getEtapasSelect: (state) => {
      let etapas = []
      state.etapas.forEach(element => {
        etapas.push({ value: element.id, text: element.nome })
      });
      return etapas
    },
    getEtapaNameById: (state) => (id) => {
      let etapa = state.etapas.find(element => element.id === id)
      return etapa ? etapa.nome : ''
    }
  },
}