import axios from 'axios'


export default {
  state: {
    estrelas: [],
  },
  mutations: {
    setEstrelas(state, payload) {
      state.estrelas = payload
    }
  },
  actions: {
    setEstrelas(context, payload) {
      context.commit('setEstrelas', payload)
    },
    loadEstrelas(context, payload) {
      axios(`/clientestrela/`).then((resp) => {
        if (resp.status < 300) {
          // console.log(resp.data);
          context.commit('setEstrelas', resp.data)
        }
      }).then((resp) => {
        if (resp) {
          if (resp.status < 300) {
            // console.log(resp.data);
            context.commit('setEstrelas', resp.data)
          }
        }
      });
    },
    savEstrelas(context, payload) {
      const metodo = payload.id ? "patch" : "post";
      const finalUrl = payload.id ? `${payload.id}` : "";
      // console.log(payload);
      if (metodo == "patch") {
        axios[metodo](`/clientestrela/${finalUrl}/`, payload).then((resp) => {
          if (resp.status < 300) {
            context.dispatch("resetSnack");
            context.dispatch("showSuccessSnack", "Nota salva com Sucesso");
            //console.log(context);
            //console.log(payload);
            context.dispatch("loadEstrelas", payload.cliente);
          }
        });

      } else {
        axios[metodo](`/clientestrela/`, payload).then((resp) => {
          //console.log(payload);
          if (resp.status < 300) {
            context.dispatch("resetSnack");
            context.dispatch("showSuccessSnack", "Nota salva com Sucesso");
            // console.log(resp.data[0].clientId);
            context.dispatch("loadEstrelas", payload.clientId);

          }

        });
      }
      let obj = {
        observacao: `Alterou a nota do cliente para ${payload.rate}`,
        uid: payload.userId,
        cliente: payload.cliente,
        metodo: "loadProspeccoesQualifiedpg",
        parametro: payload.parametro
      }
      context.dispatch("saveHistorico", obj);
    },
  },
  getters: {
    getEstrelas(state) {
      return state.estrelas
    }
  }
}
