import axios from 'axios'
export default {
    state: {
        competidores: [],
        clientesCompetidores: [],
    },
    mutations: {
        setCompetidores(state, payload) {
            state.competidores = payload
        },
        setClientesCompetidores(state, payload) {
            state.clientesCompetidores = payload
        },
    },
    actions: {
        setCompetidores(context, payload) {
            context.commit('setCompetidores', payload)
        },
        setClientesCompetidores(context, payload) {
            context.commit('setClientesCompetidores', payload)
        },
        loadCompetidores(context, payload) {
            axios(`/competidores`).then((resp) => {
                if (resp.status < 300) {
                    context.commit('setCompetidores', resp.data)
                }
            });
        },
        loadClienteCompetidor(context) {
            axios(`/clientecompetidor`).then((resp) => {
                if (resp.status < 300) {
                    context.commit('setClientesCompetidores', resp.data)
                }
            });
        }
    },
    getters: {
        getCompetidores(state) {
            return state.competidores
        },
        getCompetidoresSelect: (state) =>  {
            let gp = []
            state.competidores.forEach(element => {
                gp.push({value: element.id, text: element.nome})
            });
            return gp 
        },
        getCompetidoresById: (state) => (id) => {
            if (!state.competidores) {
                return id
            }
            let obj = state.competidores.find(g =>
                g.id == id
            )
            return obj
        },
        getCompetidoresNameById: (state) => (id) => {
            if (!state.competidores) {
                return id
            }
            let obj = state.competidores.find(g =>
                g.id == id
            )
            if(obj){
                return obj.nome
            }else{
                return ""
            }
        },
        getClientesCompetidores(state) {
            return state.clientesCompetidores
        },
    }
}