import axios from 'axios'
import utils from '../../utils/querybuilder'
export default {
    state: {
        negociacoes: [],
        negociacoesCli: [],
        negociacoesFinalizadas: [],
        negociacoesFinalizadasG: [],
        negociacoesFinalizadasP: [],
        valorFinalizadasG: 0,
        valorFinalizadasP: 0,
        dialogServicos: false,
        dialogInclusos: false,
        inclusos: []
    },
    mutations: {
        setNegociacoes(state, payload) {
            state.negociacoes = payload
        },
        setInclusos(state, payload) {
            state.inclusos = payload
        },
        setNegociacoesCli(state, payload) {
            state.negociacoesCli = payload
        },
        setNegociacoesFinalizadas(state, payload) {
            state.negociacoesFinalizadasG = []
            state.negociacoesFinalizadasP = []
            payload.forEach(element => {
                if (element.finalizado == 1) {
                    state.negociacoesFinalizadasG.push(element)
                } else if (element.finalizado == 2) {
                    state.negociacoesFinalizadasP.push(element)
                }
            });
            state.negociacoesFinalizadas = payload
        },
        setDialogServicos(state, payload) {
            state.dialogServicos = payload
        },
        setDialogInclusos(state, payload) {
            state.dialogInclusos = payload
        },
    },
    actions: {
        setNegociacoes(context, payload) {
            context.commit('setNegociacoes', payload)
        },
        setInclusos(context, payload) {
            context.commit('setInclusos', payload)
        },
        setNegociacoesCli(context, payload) {
            context.commit('setNegociacoesCli', payload)
        },
        setNegociacoesFinalizadas(context, payload) {
            context.commit('setNegociacoesFinalizadas', payload)
        },
        setDialogServicos(context, payload) {
            context.commit('setDialogServicos', payload)
        },
        setDialogInclusos(context, payload) {
            context.commit('setDialogInclusos', payload)
        },
        loadNegociacoesCli(context, payload) {
            // console.log(payload);
            // let filtro = context.getters.getFiltroPesquisa;
            // let queryString = utils.querybuilder(filtro);
            // console.log("loadNegociacoesCli",`/negociacao/cliente/${queryString}`);
            axios(`/negociacao/cliente/${payload}`).then((resp) => {
                if (resp.status < 300) {
                    context.commit('setNegociacoesCli', resp.data)
                }
            });
        },
        loadNegociacoesFunil(context) {
            let filtro = context.getters.getFiltroPesquisa;
            let queryString = utils.querybuilder(filtro);
            // console.log("loadNegociacoesFunil",`/negociacao/${queryString}`);
            axios(`/negociacao/${queryString}`).then((resp) => {
                if (resp.status < 300) {
                    context.commit('setNegociacoes', resp.data)
                }
            });
        },
        loadNegociacoes(context) {
            let filtro = context.getters.getFiltroPesquisa;
            let queryString = utils.querybuilder(filtro);
            // console.log("loadNegociacoes",`/negociacao/${queryString}`);
            axios(`/negociacao/${queryString}`).then((resp) => {
                if (resp.status < 300) {
                    context.commit('setNegociacoes', resp.data)
                }
            });
        },
        loadInclusos(context) {
            axios(`/inclusos/`).then((resp) => {
                if (resp.status < 300) {
                    // console.log(resp.data);
                    context.commit('setInclusos', resp.data)
                }
            });
        },
        loadNegociacoesFinalizadas(context) {
            let filtro = context.getters.getFiltroPesquisa;
            let queryString = utils.querybuilder(filtro);
            // console.log("loadNegociacoesFinalizadas",`/finalizada/${queryString}`);
            axios(`/finalizada/${queryString}`).then((resp) => {
                if (resp.status < 300) {
                    context.commit('setNegociacoesFinalizadas', resp.data)
                }
            });
        },
        saveNegociacao(context, payload) {
            const metodo = payload.id ? "patch" : "post";
            const finalUrl = payload.id ? `${payload.id}` : "";
            //console.log(payload);
            if (metodo == "patch") {
                // if (payload.clientId !== undefined && typeof payload.clientId !== 'number') {
                //     return;
                // }
                axios[metodo](`/negociacao/${finalUrl}/`, payload).then((resp) => {
                    if (resp.status < 300) {
                        context.dispatch("resetSnack");
                        context.dispatch("showSuccessSnack", "Negociação salva com Sucesso");
                        // console.log(resp.data);
                        // console.log(payload);
                        context.dispatch("loadNegociacoesCli", payload.clientId);

                        let iniObs = metodo == "patch" ? "Alterou a negociação do cliente " : "Criou uma negociação para o cliente.";
                        let obj = {
                            observacao: `${iniObs}`,
                            uid: payload.userId,
                            cliente: payload.clientId,
                            metodo: null,
                            parametro: null
                        }
                        context.dispatch("saveHistorico", obj);

                        let etapaCliente = {
                            etapa_id: 5,
                            cliente: payload.clientId,
                        }
                        context.dispatch("saveEtapasClientes", etapaCliente);
                    }
                });

            } else {

                // if (payload.clientId !== undefined && typeof payload.clientId !== 'number') {
                //     return;
                // }
                axios[metodo](`/negociacao/`, payload).then((resp) => {
                    // console.log(payload);
                    if (resp.status < 300) {
                        context.dispatch("resetSnack");
                        context.dispatch("showSuccessSnack", "Negociação salva com Sucesso");
                        // console.log(resp.data[0].clientId);
                        context.dispatch("loadNegociacoesCli", payload.clientId);

                        let iniObs = metodo == "patch" ? "Alterou a negociação do cliente " : "Criou uma negociação para o cliente.";
                        let obj = {
                            observacao: `${iniObs}`,
                            uid: payload.userId,
                            cliente: payload.clientId,
                            metodo: null,
                            parametro: null
                        }
                        context.dispatch("saveHistorico", obj);

                        let etapaCliente = {
                            etapa_id: 5,
                            cliente: payload.clientId,
                        }
                        context.dispatch("saveEtapasClientes", etapaCliente);
                    }

                });
            }
        },
        fechaNegociacao(context, payload) {
            const metodo = payload.id ? "patch" : "post";
            const finalUrl = payload.id ? `${payload.id}/` : "";
            axios[metodo](`/negociacao/${finalUrl}`, payload).then((resp) => {
                if (resp.status < 300) {
                    context.dispatch("resetSnack");
                    context.dispatch("showSuccessSnack", "Negociação finalizada com Sucesso");
                    context.dispatch("loadNegociacoes");
                    if (payload.finalizado == 1 || payload.finalizado == 2) {
                        context.dispatch("loadNegociacoesFinalizadas");
                    }
                }
            });
            let obj = {
                observacao: `Finalizou a negociação do cliente como ${payload.finalizado == 1 ? "Ganha" : "Perdida"}`,
                uid: payload.userId,
                cliente: payload.clientId,
                metodo: null,
                parametro: null
            }
            context.dispatch("saveHistorico", obj);
            let etapa = payload.finalizado == 1 ? 7 : 8;
            let etapaCliente = {
                etapa_id: etapa,
                cliente: payload.clientId,
            }
            context.dispatch("saveEtapasClientes", etapaCliente);
        },
        deletNegociacao(context, payload) {
            axios.delete(`/negociacao/${payload.id}`).then((resp) => {
                if (resp.status < 300) {
                    context.dispatch("resetSnack");
                    context.dispatch("showSuccessSnack", "Negociação Deletada com Sucesso");
                    context.dispatch("loadNegociacoesCli");

                    let obj = {
                        observacao: `Deletou a negociação do cliente.`,
                        uid: payload.userId,
                        cliente: payload.clientId,
                        metodo: null,
                        parametro: null
                    }
                    context.dispatch("saveHistorico", obj);
                }
            });
        },
    },
    getters: {
        getNegociacoes(state) {
            return state.negociacoes
        },
        getInclusos(state) {
            return state.inclusos
        },
        getInclusosSelect: (state) => {
            let gp = []
            state.inclusos.forEach(element => {
                gp.push({ value: element.id, text: `${element.descricao}` })
            });
            return gp
        },
        getInclusosById: (state) => (id) => {
            if (!state.inclusos) {
                return id
            }
            let obj = state.inclusos.find(g =>
                g.id == id
            )
            if (obj) {
                return obj.descricao
            } else {
                return ""

            }
        },
        getNegociacoesFG(state) {
            return state.negociacoesFinalizadasG
        },
        getNegociacoesFGValor(state) {
            return state.valorFinalizadasG
        },
        getNegociacoesFPValor(state) {
            return state.valorFinalizadasP
        },
        getNegociacoesFP(state) {
            return state.negociacoesFinalizadasP
        },
        getNegociacoesF(state) {
            return state.negociacoesFinalizadas
        },
        getNegociacoesCli(state) {
            return state.negociacoesCli
        },
        getDialogServicos(state) {
            return state.dialogServicos
        },
        getDialogInclusos(state) {
            return state.dialogInclusos
        },
        // getDialogAgendar(state) {
        //     return state.dialogAgendar
        // },
        // getTipoAgendamento(state) {
        //     return state.tipoAgendamento
        // },
        // getTipoAgendamentoNameById: (state) => (id) => {
        //     if (!state.tipoAgendamento) {
        //         return id
        //     }
        //     let obj = state.tipoAgendamento.find(g =>
        //         g.id == id
        //     )
        //     if(obj){
        //         return obj.nome
        //     }else{
        //         return ""

        //     }
        // },
        // getTipoAgendamentoSelect: (state) =>  {
        //     let gp = []
        //     state.tipoAgendamento.forEach(element => {
        //         gp.push({value: element.id, text: `${element.nome}`})
        //     });
        //     return gp 
        // },
        // getAgendamentoById: (state) => (id) => {
        //     if (!state.agendamentos) {
        //         return id
        //     }
        //     let obj = state.agendamentos.find(g =>
        //         g.id == id
        //     )
        //     return obj
        // },
    },
}