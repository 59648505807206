import axios from 'axios'
import utils from '../../utils/querybuilder'

export default {
  state: {
    prospecFunil: {},
    qualificadoFunil: {},
    agendaFunil: {},
    diagnosticoFunil: {},
    negociacaoFunil: {},
    negociacaoAtivaFunil: {},
    marcadores: [],
  },
  mutations: {
    setProspecfunil(state, payload) {
      state.prospecFunil = payload
    },
    setQualificadofunil(state, payload) {
      state.qualificadoFunil = payload
    },
    setAgendafunil(state, payload) {
      state.agendaFunil = payload
    },
    setDiagnosticoFunil(state, payload) {
      state.diagnosticoFunil = payload
    },
    setNegociacaoFunil(state, payload) {
      state.negociacaoFunil = payload
    },
    setNegociacaoAtivaFunil(state, payload) {
      state.negociacaoAtivaFunil = payload
    },
    setMarcadores(state, payload) {
      state.marcadores = payload
    },
  },
  actions: {
    // setters
    setProspecfunil(context, payload) {
      context.commit('setProspecfunil', payload)
    },
    setQualificadofunil(context, payload) {
      context.commit('setQualificadofunil', payload)
    },
    setAgendafunil(context, payload) {
      context.commit('setAgendafunil', payload)
    },
    setDiagnosticoFunil(context, payload) {
      context.commit('setDiagnosticoFunil', payload)
    },
    setNegociacaoFunil(context, payload) {
      context.commit('setNegociacaoFunil', payload)
    },
    setNegociacaoAtivaFunil(context, payload) {
      context.commit('setNegociacaoAtivaFunil', payload)
    },
    setMarcadores(context, payload) {
      context.commit('setMarcadores', payload)
    },
    // loaders
    loadProspecfunil(context, payload) {
      let filtro = context.getters.getFiltroPesquisa;
      filtro = { ...filtro, ...payload }
      let queryString = utils.querybuilder(filtro);
      axios(`/prospecfunil/${queryString}`).then((resp) => {
        if (resp.status < 300) {
          context.commit('setProspecfunil', resp.data);
        }
      });
    },
    loadQualificadofunil(context, payload) {
      let filtro = context.getters.getFiltroPesquisa;
      filtro = { ...filtro, ...payload }
      let queryString = utils.querybuilder(filtro);
      axios(`/qualificadofunil/${queryString}`).then((resp) => {
        if (resp.status < 300) {
          context.commit('setQualificadofunil', resp.data);
        }
      });
    },
    loadAgendaFunil(context, payload) {
      let filtro = context.getters.getFiltroPesquisa;
      filtro = { ...filtro, ...payload }
      let queryString = utils.querybuilder(filtro);
      axios(`/agendafunil/${queryString}`).then((resp) => {
        if (resp.status < 300) {
          context.commit('setAgendafunil', resp.data);
        }
      });
    },
    loadDiagnosticoFunil(context, payload) {
      let filtro = context.getters.getFiltroPesquisa;
      filtro = { ...filtro, ...payload }
      let queryString = utils.querybuilder(filtro);
      axios(`/diagnosticofunil/${queryString}`).then((resp) => {
        if (resp.status < 300) {
          context.commit('setDiagnosticoFunil', resp.data);
        }
      });
    },
    loadNegociacaoFunil(context, payload) {
      let filtro = context.getters.getFiltroPesquisa;
      filtro = { ...filtro, ...payload }
      let queryString = utils.querybuilder(filtro);
      axios(`/negociacaofunil/${queryString}`).then((resp) => {
        if (resp.status < 300) {
          context.commit('setNegociacaoFunil', resp.data);
        }
      });
    },
    loadNegociacaoativaFunil(context, payload) {
      let filtro = context.getters.getFiltroPesquisa;
      filtro = { ...filtro, ...payload }
      let queryString = utils.querybuilder(filtro);
      axios(`/negociacaoativafunil/${queryString}`).then((resp) => {
        if (resp.status < 300) {
          context.commit('setNegociacaoAtivaFunil', resp.data);
        }
      });
    },
    loadMarcadores(context, payload) {
      let filtro = context.getters.getFiltroPesquisa;
      filtro = { ...filtro, ...payload }
      let queryString = utils.querybuilder(filtro);
      axios(`/mapaclientes/${queryString}`).then((resp) => {
        if (resp.status < 300) {
          context.commit('setMarcadores', resp.data);
        }
      });
    },
  },
  getters: {
    getProspecFunil(state) {
      return state.prospecFunil
    },
    getQualificadoFunil(state) {
      return state.qualificadoFunil
    },
    getAgendaFunil(state) {
      return state.agendaFunil
    },
    getDiagnosticoFunil(state) {
      return state.diagnosticoFunil
    },
    getNegociacaoFunil(state) {
      return state.negociacaoFunil
    },
    getNegociacaoAtivaFunil(state) {
      return state.negociacaoAtivaFunil
    },
    getMarcadores(state) {
      return state.marcadores
    },
  },
}