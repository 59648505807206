import axios from 'axios'
import utils from '../../utils/querybuilder'
export default {
    state: {
        diagnostico:{},
        diagnosticos: [],
    },
    mutations: {
        setDiagnosticos(state, payload) {
            state.diagnosticos = payload
        },
        setDiagnostico(state, payload) {
            state.diagnostico = payload
        },
    },
    actions: {
        setDiagnosticos(context, payload) {
            context.commit('setDiagnosticos', payload)
        },
        setDiagnostico(context, payload) {
            context.commit('setDiagnostico', payload)
        },
        loadDiagnosticosFunil(context) {
            let filtro = context.getters.getFiltroPesquisa;
            // filtro = { ...filtro, ...payload }
            // console.log(filtro);
            let queryString = utils.querybuilder(filtro);            

            // console.log(payload);
            // let queryString = utils.querybuilder(payload);
            axios(`/diagnostico/${queryString}`).then((resp) => {
                if (resp.status < 300) {
                    context.commit('setDiagnosticos', resp.data)
                }
            });
        },
        loadDiagnosticos(context) {
            let filtro = context.getters.getFiltroPesquisa;
            // filtro = { ...filtro, ...payload }
            // console.log(filtro);
            let queryString = utils.querybuilder(filtro);
            axios(`/diagnostico/${queryString}`).then((resp) => {
                if (resp.status < 300) {
                    context.commit('setDiagnosticos', resp.data)
                }
            });
        },
        saveDiagnostico(context, payload) {
            const metodo = payload.id ? "patch" : "post";
            const finalUrl = payload.id ? `${payload.id}/` : "";
            axios[metodo](`/diagnostico/${finalUrl}`, payload).then((resp) => {
                if (resp.status < 300) {
                    context.dispatch("resetSnack");
                    context.dispatch("showSuccessSnack", "Diagnostico salvo com Sucesso");
                    context.dispatch("loadDiagnosticos");

                    let iniObs = metodo == "patch" ? "Atualizou o diagnostico" : "Criou o diagnostico";
                    let obj = {
                        observacao: ` ${iniObs} no cliente`,
                        cliente: payload.clientId,
                        metodo: null,
                        parametro: null
                    }
                    context.dispatch("saveHistorico", obj);

                    let etapaCliente = {
                        cliente: payload.clientId,
                        etapa_id: 4,
                    }
                    context.dispatch("saveEtapasClientes", etapaCliente);
                }
            });
        },
        deletDiagnostico(context, payload) {
            axios.delete(`/agendamento/${payload.id}`).then((resp) => {
                if (resp.status < 300) {
                    context.dispatch("resetSnack");
                    context.dispatch("showSuccessSnack", "Diagnostico Deletado com Sucesso");
                    context.dispatch("loadDiagnosticos");

                    let obj = {
                        observacao: `Deletou o diagnostico no cliente.`,
                        cliente: payload.clientId,
                        metodo: null,
                        parametro: null
                    }
                    context.dispatch("saveHistorico", obj);
                }
            });
        },
    },
    getters: {
        getDiagnosticos(state) {
            return state.diagnosticos
        },
        getDiagnostico(state) {
            return state.diagnostico
        },
        getDiagnosticoById: (state) => (id) => {
            if (!state.diagnosticos) {
                return id
            }
            let obj = state.diagnosticos.find(g =>
                g.id == id
            )
            if(obj){
                return obj
            }else{
                return {}
            }
        },
    },
}