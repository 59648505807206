

export default ({
  state: {
    showMap: false
  },
  mutations: {
    setShowMap(state, value) {
      state.showMap = value;
    }
  },
  actions: {
    updateShowMap({ commit }, value) {
      commit('setShowMap', value);
    }
  },
  getters: {
    getShowMap(state) {
      return state.showMap;
    }
  }
});